import React, { useState } from "react";

const FileInput = ({
  label,
  value,
  required = false,
  accept,
  onChange,
  ...props
}) => {
  const [selectedFile, setSelectedFile] = useState();

  const handleChange = (e) => {
    setSelectedFile(e.target.files[0].name);
    onChange(e);
  };
  return (
    <div className="form-group">
      {label && (
        <div>
          <label htmlFor="input-field">
            {label} <span className="required-star">{required ? "*" : ""}</span>
          </label>
        </div>
      )}
      <input
        type="file"
        id={props.name}
        accept={accept ? accept : "image/*,application/pdf"}
        onChange={handleChange}
        hidden
        {...props}
      />
      <label className="form-control" htmlFor={props.name}>
        &nbsp; &nbsp;
        <>
          {accept && accept.match(/image/) ? (
            <i className="fa fa-camera"></i>
          ) : (
            <i className="fa fa-file"></i>
          )}
          &nbsp;
          {selectedFile ? selectedFile : <span>Choose file</span>}
        </>
      </label>
    </div>
  );
};

export default FileInput;
