import Login from "../../pages/users/Login";
import Profile from "../../pages/users/Profile";
import VerifyUser from "../../pages/users/VerifyUser";
import Dashboard from "../../pages/users/Dashboard";
import ResetPassword from "../../pages/users/ResetPassword";
import AllUsers from "../../pages/users/AllUsers";
import SignUpBusiness from "../../pages/users/SignUpBusiness";
import AllProductType from "../../pages/users/AllProductType";
import InventorySales from "../../pages/users/InventorySales";
import InventoryAnalytics from "../../pages/users/InventoryAnalytics";
import AllBranches from "../../pages/users/AllBranches";
import AllServiceType from "../../pages/users/AllServiceType";
import InventoryProducts from "../../pages/users/InventoryProducts";
import InventoryServices from "../../pages/users/InventoryServices";
import InventoryTransactions from "../../pages/users/InventoryTransactions";
import InventoryTransaction from "../../pages/users/InventoryTransaction";
import InventorySale from "../../pages/users/InventorySale";
import ManageInvestment from "../../pages/users/ManageInvestment";
import ManageDebt from "../../pages/users/ManageDebt";
import ManageExpenses from "../../pages/users/ManageExpenses";
import AllBusinessType from "../../pages/users/AllBusinessType";
import ManageDeposit from "../../pages/users/ManageDeposit";
import RequestInvestment from "../../pages/users/RequestInvestment";
import InvestmentDetail from "../../pages/users/InvestmentDetail";

let user = "user";
let product = "product";
let service = "service";

let routes = [
  {
    path: `${user}/register/business`,
    component: SignUpBusiness,
    protected: false,
  },
  {
    path: `${user}/reset-password`,
    component: ResetPassword,
    protected: false,
  },
  {
    path: `${user}/verify-account`,
    component: VerifyUser,
    protected: false,
  },
  {
    path: `${user}/profile`,
    component: Profile,
    protected: true,
  },
  {
    path: `${user}/all`,
    component: AllUsers,
    protected: true,
  },
  {
    path: `branch/all`,
    component: AllBranches,
    protected: true,
  },
  {
    path: `${user}/login`,
    component: Login,
    protected: false,
  },
  {
    path: `/`,
    component: Dashboard,
    protected: true,
  },
  {
    path: `dashboard`,
    component: Dashboard,
    protected: true,
  },
  {
    path: `${product}/types`,
    component: AllProductType,
    protected: true,
  },
  {
    path: `business/types`,
    component: AllBusinessType,
    protected: true,
  },
  {
    path: `${service}/types`,
    component: AllServiceType,
    protected: true,
  },
  {
    path: `inventory/products`,
    component: InventoryProducts,
    protected: true,
  },
  {
    path: `investment`,
    component: ManageInvestment,
    protected: true,
  },
  {
    path: `investment/details/:id`,
    component: InvestmentDetail,
    protected: true,
  },
  {
    path: `deposit`,
    component: ManageDeposit,
    protected: true,
  },
  {
    path: `debt`,
    component: ManageDebt,
    protected: true,
  },
  {
    path: `expenses`,
    component: ManageExpenses,
    protected: true,
  },
  {
    path: `investment/request`,
    component: RequestInvestment,
    protected: true,
  },
  {
    path: `inventory/sales`,
    component: InventorySales,
    protected: true,
  },
  {
    path: `inventory/sale`,
    component: InventorySale,
    protected: true,
  },
  {
    path: `inventory/services`,
    component: InventoryServices,
    protected: true,
  },

  {
    path: `inventory/transactions`,
    component: InventoryTransactions,
    protected: true,
  },
  {
    path: `inventory/transaction`,
    component: InventoryTransaction,
    protected: true,
  },
  {
    path: `inventory/analytics`,
    component: InventoryAnalytics,
    protected: true,
  },
];
export default routes;
