import React, { useState } from "react";
import { useEffect } from "react";
import OtherPageBody from "./OtherPageBody";
import { inventoryService } from "../../service/inventory.service";
import { ToastContainer, toast } from "react-toastify";
import Loader from "./components/loaders/Loader";
import InputField from "./components/inputs/InputField";
import { AttachMoney } from "@mui/icons-material";
import DiscountOutlinedIcon from "@mui/icons-material/DiscountOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import InventoryIcon from "@mui/icons-material/Inventory";
import toMoney from "number-to-money";
import { useContext } from "react";
import { UserContext } from "../../Access";
import { UserRole } from "../../enums/UserRole";
import { BusinessTarget } from "../../enums/BusinessTarget";
import { debtService } from "../../service/debt.service";
import { Link } from "react-router-dom";
import { expenseService } from "../../service/expense.service";
import { titleCase } from "title-case";
import { storage } from "../../utils/constants/storage";
import OptionField from "./components/select/OptionField";
import { userService } from "../../service/user.service";

const InventoryAnalytics = () => {
  const userSession = useContext(UserContext);
  const user = userSession.user;
  const [cycle, setCycle] = useState(storage.get('cycle'))

  const [inventoryAnalytics, setInventoryAnalytics] = useState([]);
  const [analyticsProgress, setAnalyticsProgress] = useState(false);
  const [debtAnalyticsProgress, setDebtAnalyticsProgress] = useState(false);
  const [expenseAnalyticsProgress, setExpenseAnalyticsProgress] =
    useState(false);
  const [debtAnalytics, setDebtAnalytics] = useState({});
  const [expenseAnalytics, setExpenseAnalytics] = useState({});
  const [productProgress, setProductProgress] = useState(false);
  const [servicesProgress, setServicesProgress] = useState(false);
  const [inventoryAnalyticsTwo, setInventoryAnalyticsTwo] = useState({});
  const [productInventory, setProductInventory] = useState({});
  const [serviceInventory, setServiceInventory] = useState({});
  const [inventoryAnalyticFile, setInventoryAnalyticFile] = useState();
  const [input, setInput] = useState({ from: '', to: '', actionBy: '' });
  const [allUsers, setAllUsers] = useState(['All...']);


  const getUsers = () => {
    userService
      .getAllUsers(0)
      .then((result) => {
        if (result.data.success) {
          const users = result.data.data.page;
          const list = ['All...'];
          users.forEach((user) => {
            list.push(user.name)
          })
          setAllUsers(list);

        } else {
          toast.error(result.data.message)
        }
      })
      .catch((e) => {
        console.log(e)
      });
  };
  const getInventoryAnalytics = () => {
    const request = {
      from: input.from,
      to: input.to,
      actionBy: input.actionBy,
    };
    setAnalyticsProgress(true);
    inventoryService
      .getInventoryAnalytics(request)
      .then((response) => {
        if (response.data.success) {
          setInventoryAnalytics(response.data.data.breakdown);
          setInventoryAnalyticsTwo(response.data.data);
          //download report in a file
          inventoryService
            .downloadInventoryAnalytics(request)
            .then((response) => {
              console.log(response);
              setAnalyticsProgress(false);
              if (response.data.success) {
                setInventoryAnalyticFile(response.data.data);
              }
            })
            .catch((err) => setAnalyticsProgress(false));
        }
      })
      .catch((err) => setAnalyticsProgress(false));
  };

  const getProductInventory = () => {
    setProductProgress(true);
    inventoryService
      .getProductInventory()
      .then((response) => {
        setProductProgress(false);
        if (response.data.success) {
          setProductInventory(response.data.data);
        }
      })
      .catch((err) => setProductProgress(false));
  };

  const getServiceInventory = () => {
    setServiceInventory(true);
    inventoryService
      .getServiceInventory()
      .then((response) => {
        setServiceInventory(false);
        if (response.data.success) {
          setServiceInventory(response.data.data);
        }
      })
      .catch((err) => setServiceInventory(false));
  };

  const getDebtAnalytics = () => {
    setDebtAnalyticsProgress(true);
    debtService
      .getDebtAnalytics()
      .then((response) => {
        setDebtAnalyticsProgress(false);
        console.log(response.data);
        if (response.data.success) {
          setDebtAnalytics(response.data.data);
        }
      })
      .catch((err) => setDebtAnalyticsProgress(false));
  };

  const getExpenseAnalytics = () => {
    setExpenseAnalyticsProgress(true);
    expenseService
      .getExpenseAnalytics()
      .then((response) => {
        setExpenseAnalyticsProgress(false);
        console.log(response.data);
        if (response.data.success) {
          setExpenseAnalytics(response.data.data);
        }
      })
      .catch((err) => setExpenseAnalyticsProgress(false));
  };
  useEffect(() => {
    getUsers();
    getInventoryAnalytics();
    getDebtAnalytics();
    getExpenseAnalytics();
    if (user.businessTarget !== BusinessTarget.Service) getProductInventory();
    if (user.businessTarget !== BusinessTarget.Product) getServiceInventory();
  }, []);

  return (
    <OtherPageBody>
      <ToastContainer />
      <div className="container-fluid py-5 px-4">
        <div className="header-card mt--3">
          <div className="card-header">
            <h4 className="text-left">
              <i className="fa fa-pencil"></i> Report & Analytics
            </h4>
            <small>
              {cycle ? titleCase(cycle.toLowerCase()) : 'Daily'} report, change the start and end date below to filter
              previous transactions
            </small>
          </div>
        </div>
        <div className="justify-content-left flex-fill">
          <div className="row inventory-summary-cards">
            <div className="col-xl-2 col-lg-2 col-md-2 col-6">
              <div className="card">
                <Link to="/inventory/transactions">
                  <div className="card-body">
                    <div className="d-flex">
                      <div className="">
                        <div className="card-title font-weight-bold">
                          {user.businessTarget === BusinessTarget.Service
                            ? "Trans"
                            : "Sales"}
                        </div>
                        <div className="card-text">
                          {analyticsProgress ? (
                            <Loader />
                          ) : (
                            <>
                              ₦
                              {inventoryAnalyticsTwo.total
                                ? toMoney(inventoryAnalyticsTwo.total)
                                : "0.00"}
                            </>
                          )}
                        </div>
                      </div>
                      <div className="ml-auto summ-icon">
                        <AttachMoney style={{ fontSize: "50px" }} />
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-2 col-6">
              <div className="card">
                <div className="card-body ">
                  <div className="d-flex">
                    <div className="">
                      <div className="card-title font-weight-bold">Revenue</div>
                      <div className="card-text">
                        {analyticsProgress ? (
                          <Loader />
                        ) : (
                          <>
                            ₦
                            {user.role !== UserRole.User
                              ? inventoryAnalyticsTwo.totalRevenue
                                ? toMoney(inventoryAnalyticsTwo.totalRevenue)
                                : "0.00"
                              : "****"}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="ml-auto summ-icon">
                      <ReceiptOutlinedIcon style={{ fontSize: "40px" }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-2 col-6">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex">
                    <div className="">
                      <div className="card-title font-weight-bold">
                        Discounts
                      </div>
                      <div className="card-text">
                        {analyticsProgress ? (
                          <Loader />
                        ) : (
                          <>
                            ₦
                            {inventoryAnalyticsTwo.totalDiscount
                              ? toMoney(inventoryAnalyticsTwo.totalDiscount)
                              : "0.00"}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="ml-auto summ-icon">
                      <DiscountOutlinedIcon style={{ fontSize: "40px" }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-2 col-lg-2 col-md-2 col-6">
              <div className="card">
                <Link to="/debt">
                  <div className="card-body">
                    <div className="d-flex">
                      <div className="">
                        <div className="card-title font-weight-bold">Debt</div>
                        <div className="card-text">
                          {debtAnalyticsProgress ? (
                            <Loader />
                          ) : (
                            <>
                              ₦
                              {debtAnalytics.totalAmount
                                ? toMoney(
                                  debtAnalytics.totalAmount -
                                  debtAnalytics.totalCleared
                                )
                                : "0"}
                            </>
                          )}
                        </div>
                      </div>
                      <div className="ml-auto summ-icon">
                        {/* <FormatListBulletedIcon style={{ fontSize: "40px" }} /> */}
                        <AttachMoney style={{ fontSize: "40px" }} />
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-xl-2 col-lg-2 col-md-2 col-6">
              <div className="card">
                <div className="card-body">
                  <Link to="/expenses">
                    <div className="d-flex">
                      <div className="">
                        <div className="card-title font-weight-bold">
                          Expenses
                        </div>
                        <div className="card-text">
                          {expenseAnalyticsProgress ? (
                            <Loader />
                          ) : (
                            <>
                              ₦
                              {expenseAnalytics.totalAmount
                                ? toMoney(expenseAnalytics.totalAmount)
                                : "0"}
                            </>
                          )}
                        </div>
                      </div>
                      <div className="ml-auto summ-icon">
                        <AttachMoney style={{ fontSize: "40px" }} />
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-xl-2 col-lg-2 col-md-2 col-6">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex">
                    <div className="">
                      <div className="card-title font-weight-bold">
                        {user.businessTarget === BusinessTarget.Service
                          ? "Services"
                          : "Inventory"}
                      </div>
                      <div className="card-text">
                        {productProgress || servicesProgress ? (
                          <Loader />
                        ) : (
                          <>
                            {user.businessTarget === BusinessTarget.Service
                              ? serviceInventory && serviceInventory.left
                                ? serviceInventory.left
                                : "****"
                              : productInventory && productInventory.left
                                ? productInventory.left
                                : "****"}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="ml-auto summ-icon">
                      {/* <FormatListBulletedIcon style={{ fontSize: "40px" }} /> */}
                      <InventoryIcon style={{ fontSize: "40px" }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-4 mb-5">
          <h5 className="font-weight-bold">Transaction History</h5>
          <div className="filter-container d-flex  align-item-left">
            <OptionField
              children={allUsers.length > 0 ? allUsers : ['All...']}
              name="actionBy"
              value={input.actionBy}
              placeholder="Action by"
              label="Action by"
              onChange={(e) =>
                setInput({
                  ...input,
                  actionBy: e.target.value,
                })
              }
            />
            <InputField
              type="date"
              name="from"
              value={input.from}
              placeholder="From"
              label="Start Date"
              onChange={(e) =>
                setInput({
                  ...input,
                  from: e.target.value,
                })
              }
              required
            />
            <InputField
              type="date"
              name="to"
              value={input.to}
              placeholder="To"
              label="End Date"
              onChange={(e) =>
                setInput({
                  ...input,
                  to: e.target.value,
                })
              }
              required
            />
            <div className="filter-button text-align-right">
              <button
                type="button"
                onClick={() => {
                  getInventoryAnalytics();
                }}
                className="btn default-btn btn-primary btn-large p-2"
              >
                Filter
              </button>
            </div>
          </div>
        </div>

        {analyticsProgress ? (
          <div className="card table-responsive border">
            <div className="card-body">
              <Loader />
            </div>
          </div>
        ) : inventoryAnalytics.length > 0 ? (
          <div className="card table-responsive border">
            <div className="card-body">
              <table className="table table-hover table-striped">
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Total</th>
                    <th>Revenue</th>
                  </tr>
                </thead>
                <tbody>
                  {inventoryAnalytics.map((elem, index) => {
                    return (
                      <tr key={elem.productId}>
                        <td>{++index}</td>
                        <td>{elem.itemName}</td>
                        <td>{elem.itemType}</td>
                        <td>₦{toMoney(elem.total)}</td>
                        <td>
                          ₦
                          {user.role !== UserRole.User
                            ? toMoney(elem.totalRevenue)
                            : "****"}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="text-align-right align-items-right">
                <a
                  className="btn default-btn"
                  href={inventoryAnalyticFile ? inventoryAnalyticFile.url : "#"}
                  rel="noreferrer"
                  target="_BLANK"
                  download="report.csv"
                >
                  <i className="fa fa-cloud-download"></i>
                  DownLoad Report
                </a>
              </div>
            </div>
          </div>
        ) : (
          <div className="card table-responsive border">
            <div className="card-body text-center">No transaction found</div>
          </div>
        )}
      </div>
    </OtherPageBody>
  );
};

export default InventoryAnalytics;
