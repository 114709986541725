import { util } from "../utils/constants/constants";
import { http } from "../utils/constants/http";

let productService = {
  createProductType: async (body) => {
    return await http.post(`${util.endPoint}/product-type`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  updateProductType: async (id, body) => {
    return await http.put(`${util.endPoint}/product-type/${id}`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  deleteProductType: async (id) => {
    return await http.delete(`${util.endPoint}/product-type/${id}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  getAllProductType: async () => {
    return await http.get(`${util.endPoint}/product-type/list`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
};

export { productService };
