import { util } from "../utils/constants/constants";
import { http } from "../utils/constants/http";
import { storage } from "../utils/constants/storage";

let depositService = {
  createDeposit: async (body) => {
    return await http.post(`${util.endPoint}/deposit`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  updateDeposit: async (id, body) => {
    return await http.put(`${util.endPoint}/deposit/${id}`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  deleteDeposit: async (id) => {
    return await http.delete(`${util.endPoint}/deposit/${id}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  getAllDeposit: async (filter, currentPage) => {
    let url = `/deposit/list?page=${currentPage}`;
    if (filter && filter.from && filter.to) {
      url += `&from=${filter.from}&to=${filter.to}`;
    } else {
      const currentCycle = util.decodeCycle(storage.get('cycle'))
      url += `&from=${currentCycle.from}&to=${currentCycle.to}`;
    }

    if (filter && filter.actionBy)
      url += `&actionBy=${filter.actionBy}`

    return await http.get(`${util.endPoint}${url}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  searchDeposits: async (filter, currentPage, searchText) => {
    let url = `/deposit/search?page=${currentPage}&q=${searchText}`;
    if (filter && filter.from && filter.to) {
      url += `&from=${filter.from}&to=${filter.to}`;
    } else {
      const currentCycle = util.decodeCycle(storage.get('cycle'))
      url += `&from=${currentCycle.from}&to=${currentCycle.to}`;
    }

    if (filter && filter.actionBy)
      url += `&actionBy=${filter.actionBy}`


    return await http.get(`${util.endPoint}/${url}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  getDepositAnalytics: async (filter) => {
    let url = `${util.endPoint}/report/deposit/analytics`
    if (filter && filter.from && filter.to) {
      url += `?from=${filter.from}&to=${filter.to}`;
    } else {
      const currentCycle = util.decodeCycle(storage.get('cycle'))
      url += `?from=${currentCycle.from}&to=${currentCycle.to}`;
    }
    if (filter && filter.actionBy)
      url += `&actionBy=${filter.actionBy}`

    return await http.get(url, {
      headers: util.getAuthorizedHeaders(),
    });
  },
};

export { depositService };
