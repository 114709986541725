import React from "react";
import logo from "./../../../assets/img/logoWhite.png";


const Footer = () => {
  return (
    <div>
      <footer className="footer">
        <div className="container-fluid">
          <div className="copyright ml-auto">
            Copyright © 2024 <img src={logo} alt="logo" height="20px"/>. All
            Rights Reserved.
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
