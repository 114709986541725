import React from 'react';

const TextArea = ({ label, value, required = false, onChange, ...props }) => {

  return (
    <div className="form-group">
      {label && <label htmlFor="comment">{label}</label>}
      <span className="required-star">{required ? '*' : ''}</span>
      <textarea
        className="form-control"
        id="comment"
        rows="2"
        onChange={onChange}
        {...props}
        defaultValue={value ? value : ''}
      ></textarea>
    </div>
  );
};

export default TextArea;
