import { util } from "../utils/constants/constants";
import { http } from "../utils/constants/http";
import { storage } from "../utils/constants/storage";

let investmentService = {
  createInvestment: async (body) => {
    return await http.post(`${util.endPoint}/investment`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  updateInvestment: async (id, body) => {
    return await http.put(`${util.endPoint}/investment/${id}`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  deleteInvestment: async (id) => {
    return await http.delete(`${util.endPoint}/investment/${id}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  getInvestmentById: async (id) => {
    return await http.get(`${util.endPoint}/investment/detail/${id}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  getAllInvestment: async (filter, currentPage) => {
    let url = `/investment/list?page=${currentPage}`;
    if (filter && filter.from && filter.to) {
      url += `&from=${filter.from}&to=${filter.to}`;
    } else {
      const currentCycle = util.decodeCycle(storage.get("cycle"));
      url += `&from=${currentCycle.from}&to=${currentCycle.to}`;
    }

    if (filter && filter.actionBy) url += `&actionBy=${filter.actionBy}`;

    return await http.get(`${util.endPoint}${url}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  searchInvestments: async (filter, currentPage, searchText) => {
    let url = `/investment/search?page=${currentPage}&q=${searchText}`;
    if (filter && filter.from && filter.to) {
      url += `&from=${filter.from}&to=${filter.to}`;
    } else {
      const currentCycle = util.decodeCycle(storage.get("cycle"));
      url += `&from=${currentCycle.from}&to=${currentCycle.to}`;
    }

    if (filter && filter.actionBy) url += `&actionBy=${filter.actionBy}`;

    return await http.get(`${util.endPoint}/${url}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  getInvestmentAnalytics: async (filter) => {
    let url = `${util.endPoint}/report/investment/analytics`;
    if (filter && filter.from && filter.to) {
      url += `?from=${filter.from}&to=${filter.to}`;
    } else {
      const currentCycle = util.decodeCycle(storage.get("cycle"));
      url += `?from=${currentCycle.from}&to=${currentCycle.to}`;
    }

    if (filter && filter.actionBy) url += `&actionBy=${filter.actionBy}`;

    return await http.get(url, {
      headers: util.getAuthorizedHeaders(),
    });
  },
};

export { investmentService };
