import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import OtherPageBody from "./OtherPageBody";
import { useContext } from "react";
import { UserContext } from "../../Access";
import SearchField from "./components/inputs/SearchField";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { investmentService } from "../../service/investmen.service";
import TinyLoader from "./components/loaders/TinyLoader";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import InputField from "./components/inputs/InputField";
import toMoney from "number-to-money";
import { format } from "timeago.js";
import Loader from "./components/loaders/Loader";
import { titleCase } from "title-case";

const ManageInvestment = () => {
  const userSession = useContext(UserContext);
  const user = userSession.user;
  const [response, setResponse] = useState([]);
  const [inProgress, setInProgress] = useState(false);
  const [openInvestmentUpdateModal, setOpenInvestmentEditModal] =
    useState(false);
  const [openDeleteInvestmentModal, setOpenDeleteInvestmentModal] =
    useState(false);
  const [searchText, setSearchText] = useState("");
  const [totalPages, setTotalPage] = useState();
  let [currentPage, setCurrentPage] = useState(0);
  const { width } = useWindowDimensions();
  const [input, setInput] = useState({ from: "", to: "" });
  const [registerInvestmentInput, setRegisterInvestmentInput] = useState({
    title: "",
    amount: 0,
    investmentDate: "",
    description: "",
  });
  const [updateInvestmentProgress, setUpdateInvestmentProgress] =
    useState(false);

  const [deleteProgress, setDeleteProgress] = useState(false);
  const [selectedInvestment, setSelectedInvestment] = useState(false);

  useEffect(() => {
    getAllInvestments();
  }, []);

  const getAllInvestments = () => {
    const request = {
      from: input.from,
      to: input.to,
    };
    setInProgress(true);
    investmentService
      .getAllInvestment(request, currentPage)
      .then((result) => {
        setInProgress(false);
        if (result.data.success) {
          setResponse(result.data.data.page);
        }
      })
      .catch((e) => {
        setResponse([]);
        setInProgress(false);
      });
  };

  const changePage = (action) => {
    if (action === -1) {
      if (currentPage > 0) currentPage--;
    } else if (action === +1) {
      if (currentPage < totalPages) currentPage++;
    } else {
      currentPage = action;
    }
    setCurrentPage(currentPage);
    if (searchText && searchText.length >= 3) performSearch();
    else getAllInvestments();
  };

  const performSearch = () => {
    if (searchText && searchText.length >= 3) {
      const request = {
        from: input.from,
        to: input.to,
      };
      setInProgress(true);
      investmentService
        .searchInvestments(request, currentPage, searchText)
        .then((result) => {
          setInProgress(false);
          if (result.data.success) {
            const { page } = result.data.data;
            const { data } = result.data;
            setResponse(page);
            if (page.length > 0) {
              setTotalPage(data.totalPages);
              setCurrentPage(data.currentPage);
            }
          } else {
            setResponse([]);
          }
        })
        .catch((e) => {
          setResponse([]);
          setInProgress(false);
        });
    }
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
    performSearch();
  };
  const performDelete = () => {
    setDeleteProgress(true);
    investmentService
      .deleteInvestment(selectedInvestment._id)
      .then((result) => {
        setOpenDeleteInvestmentModal(false);
        setDeleteProgress(false);
        if (result.data.success) {
          toast.success("Deleted successful");
          getAllInvestments();
        } else {
          toast.error(result.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error(e.response.data.message);
        setOpenDeleteInvestmentModal(false);
        setDeleteProgress(false);
      });
  };

  const performUpdate = () => {
    setUpdateInvestmentProgress(true);
    investmentService
      .updateInvestment(selectedInvestment._id, selectedInvestment)
      .then((result) => {
        setOpenInvestmentEditModal(false);
        setUpdateInvestmentProgress(false);
        if (result.data.success) {
          toast.success("Update successful");
          getAllInvestments();
        } else {
          toast.error(result.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error(e.response.data.message);
        setOpenInvestmentEditModal(false);
        setUpdateInvestmentProgress(false);
      });
  };

  useEffect(() => {
    getAllInvestments();
  }, []);
  return (
    <>
      <OtherPageBody>
        <ToastContainer />
        <Dialog open={openInvestmentUpdateModal}>
          <DialogTitle>Update Investment</DialogTitle>
          <DialogContent>
            <InputField
              type="text"
              name="title"
              value={selectedInvestment.title}
              placeholder="Title E.G Fuel"
              label="Investment Title"
              onChange={(e) =>
                setSelectedInvestment({
                  ...selectedInvestment,
                  title: e.target.value,
                })
              }
              required
            />

            <InputField
              type="number"
              name="amount"
              value={selectedInvestment.amount}
              placeholder="Amount"
              label="Amount"
              onChange={(e) =>
                setSelectedInvestment({
                  ...registerInvestmentInput,
                  amount: e.target.value,
                })
              }
              required
            />
            <InputField
              type="date"
              name="repaymentDate"
              value={selectedInvestment.expenseDate}
              placeholder="Date"
              label="Date (Optional)"
              onChange={(e) =>
                setSelectedInvestment({
                  ...selectedInvestment,
                  expenseDate: e.target.value,
                })
              }
            />
            <InputField
              type="text"
              name="description"
              value={selectedInvestment.description}
              placeholder="Description"
              label="Remark"
              onChange={(e) =>
                setSelectedInvestment({
                  ...selectedInvestment,
                  description: e.target.value,
                })
              }
            />
          </DialogContent>
          <DialogActions>
            <button
              onClick={() => setOpenInvestmentEditModal(false)}
              className="btn btn-primary btn-border btn-round mr-2"
            >
              Cancel
            </button>
            <button
              onClick={!updateInvestmentProgress ? performUpdate : null}
              className="btn btn-primary btn-round"
            >
              {updateInvestmentProgress ? <TinyLoader /> : "Continue"}
            </button>
          </DialogActions>
        </Dialog>
        {/* Delete */}
        <Dialog open={openDeleteInvestmentModal}>
          <DialogTitle>Delete Investment</DialogTitle>
          <DialogContent>
            <p>Are you sure you want to delete this expense?</p>
          </DialogContent>
          <DialogActions>
            <button
              onClick={() => {
                setOpenDeleteInvestmentModal(false);
              }}
              className="btn btn-danger btn-border btn-round mr-2"
            >
              No
            </button>
            <button
              onClick={!deleteProgress ? performDelete : null}
              className="btn btn-primary btn-round"
            >
              {deleteProgress ? <TinyLoader /> : "Yes"}
            </button>
          </DialogActions>
        </Dialog>

        <div className="page-inner">
          <div className="row">
            <div className="col-md-12">
              <div className="header-card">
                <div className="card-header">
                  <div className="d-flex align-items-center">
                    <h4 className="card-title">All Investments</h4>
                    <SearchField
                      value={searchText}
                      placeholder="Search requests"
                      onChange={() => {}}
                    />

                    <Link
                      to="/investment/request"
                      className="btn default-btn btn-primary btn-round  btn-large ml-auto"
                    >
                      <i className="fa fa-plus"></i> &nbsp; Request Funds
                    </Link>
                  </div>
                </div>

                <div className="filter-container d-flex align-item-center text-align-center">
                  <InputField
                    type="date"
                    name="from"
                    value={input.from}
                    placeholder="From"
                    label="From"
                    onChange={(e) =>
                      setInput({
                        ...input,
                        from: e.target.value,
                      })
                    }
                  />
                  <InputField
                    type="date"
                    name="to"
                    value={input.to}
                    placeholder="To"
                    label="To"
                    onChange={(e) =>
                      setInput({
                        ...input,
                        to: e.target.value,
                      })
                    }
                  />
                  <div className="filter-button text-align-right">
                    <button
                      type="button"
                      onClick={() => {
                        getAllInvestments();
                      }}
                      className="btn default-btn btn-primary btn-large p-2"
                    >
                      Filter
                    </button>
                  </div>
                </div>
                <br />

                <div className="row data-wrapper">
                  {inProgress ? (
                    <div className="row card">
                      <Loader></Loader>
                    </div>
                  ) : response.length > 0 ? (
                    width > 800 ? (
                      <div className="row card">
                        <div className="card-body">
                          <div className="table-responsive table-hover">
                            <table id="add-row" className="table table-striped">
                              <thead>
                                <tr>
                                  <th>S/n</th>
                                  <th>Id</th>
                                  <th>Business</th>
                                  <th>Telephone</th>
                                  <th>Amount</th>
                                  <th>Return Amount</th>
                                  <th>Status</th>
                                  <th>Payment Date</th>
                                  <th>Action</th>
                                </tr>
                              </thead>

                              <tbody>
                                {response.map((item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{++index}</td>
                                      <td>
                                        <Link
                                          to={`/investment/details/${item.iuid}`}
                                        >
                                          #IR-{item.code}
                                        </Link>
                                      </td>
                                      <td>{item.businessName}</td>
                                      <td>{item.ownerPhoneNumber}</td>
                                      <td>₦{toMoney(item.amount)}</td>
                                      <td>
                                        ₦{toMoney(item.repaymentAmount)} /
                                        {titleCase(
                                          item.revenueSharingMethod.toLowerCase()
                                        )}
                                      </td>
                                      <td className={item.status}>
                                        {item.status}
                                      </td>
                                      <td>
                                        {item.status !== "UNDERREVIEW"
                                          ? format(item.repaymentStartDate)
                                          : "PENDING"}
                                      </td>
                                      <td>
                                        {user.role === "ADMIN" ? (
                                          <div className="form-button-action">
                                            <button
                                              type="button"
                                              data-toggle="tooltip"
                                              title=""
                                              className="btn btn-primary bg-danger"
                                              data-original-title="view"
                                              onClick={() => {}}
                                            >
                                              <i className="fa fa-times"></i>{" "}
                                              Reject
                                            </button>
                                            &nbsp;
                                            <button
                                              type="button"
                                              data-toggle="tooltip"
                                              title=""
                                              className="btn btn-primary"
                                              data-original-title="view"
                                              onClick={() => {}}
                                            >
                                              <i className="fa fa-check"></i>{" "}
                                              Approve
                                            </button>
                                          </div>
                                        ) : (
                                          <>
                                            {" "}
                                            <div className="form-button-action">
                                              <button
                                                type="button"
                                                data-toggle="tooltip"
                                                title=""
                                                className="btn btn-primary bg-success"
                                                data-original-title="view"
                                                onClick={() => {}}
                                              >
                                                <i className="fa fa-plus"></i>
                                                Pay
                                              </button>
                                              <button
                                                type="button"
                                                data-toggle="tooltip"
                                                title=""
                                                className="btn btn-primary bg-danger"
                                                data-original-title="view"
                                                onClick={() => {}}
                                              >
                                                <i className="fa fa-share"></i>
                                              </button>
                                              &nbsp;
                                              <button
                                                type="button"
                                                data-toggle="tooltip"
                                                title=""
                                                className="btn btn-primary"
                                                data-original-title="view"
                                                onClick={() => {
                                                  setSelectedInvestment(item);
                                                  setOpenInvestmentEditModal(
                                                    true
                                                  );
                                                }}
                                              >
                                                <i className="fa fa-edit"></i>{" "}
                                              </button>
                                            </div>
                                          </>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    ) : (
                      response.map((item, index) => {
                        return (
                          <div
                            className="list-card-item col-xl-2 col-lg-4 col-md-4 col-12"
                            key={index}
                          >
                            <div className="image-flip">
                              <div className="mainflip flip-0">
                                <div className="frontside">
                                  <div className="card">
                                    <div className="invoice-ribbon">
                                      <div
                                        style={{ fontSize: "12px" }}
                                        className={`ribbon-inner  ${
                                          item.clearedAmount >= item.amount
                                            ? "bg-primary"
                                            : "bg-danger"
                                        }`}
                                      >
                                        {titleCase(item.status.toLowerCase())}
                                      </div>
                                    </div>
                                    <div className="card-body text-left">
                                      <h4 className="card-title text-primary">
                                        {item.businessName}
                                      </h4>
                                      <ul className="card-text">
                                        <li>
                                          <h2>
                                            <strong>{item.ownerName}</strong>
                                          </h2>
                                        </li>
                                        <li>
                                          <h2>
                                            <strong>
                                              Tel: {item.ownerPhoneNumber}
                                            </strong>
                                          </h2>
                                        </li>

                                        <li>
                                          Total Amount:{" "}
                                          <span className="text-danger">
                                            ₦{toMoney(item.amount)}
                                          </span>
                                        </li>
                                        <li>
                                          Return Amount:{" "}
                                          <span className="text-danger">
                                            ₦{toMoney(item.repaymentAmount)}
                                          </span>
                                          /
                                          <span className="text-danger">
                                            {titleCase(
                                              item.revenueSharingMethod.toLowerCase()
                                            )}
                                          </span>
                                        </li>

                                        <li>
                                          Next Payment Date :
                                          <span className="text-primary">
                                            {format(item.repaymentStartDate)}
                                          </span>
                                        </li>
                                        <li>
                                          Created :
                                          <span className="text-primary">
                                            {format(item.createdAt)}
                                          </span>
                                        </li>
                                      </ul>

                                      {user.role === "ADMIN" ? (
                                        <div className="form-button-action">
                                          <button
                                            type="button"
                                            data-toggle="tooltip"
                                            title=""
                                            className="btn btn-primary bg-danger"
                                            data-original-title="view"
                                            onClick={() => {}}
                                          >
                                            <i className="fa fa-times"></i>{" "}
                                            Reject
                                          </button>
                                          &nbsp;
                                          <button
                                            type="button"
                                            data-toggle="tooltip"
                                            title=""
                                            className="btn btn-primary"
                                            data-original-title="view"
                                            onClick={() => {}}
                                          >
                                            <i className="fa fa-check"></i>{" "}
                                            Approve
                                          </button>
                                        </div>
                                      ) : (
                                        <>
                                          {" "}
                                          <div className="form-button-action">
                                            <button
                                              type="button"
                                              data-toggle="tooltip"
                                              title=""
                                              className="btn btn-primary bg-success"
                                              data-original-title="view"
                                              onClick={() => {}}
                                            >
                                              <i className="fa fa-plus"></i>
                                              Pay
                                            </button>
                                            <button
                                              type="button"
                                              data-toggle="tooltip"
                                              title=""
                                              className="btn btn-primary bg-danger"
                                              data-original-title="view"
                                              onClick={() => {}}
                                            >
                                              <i className="fa fa-share"></i>
                                            </button>
                                            &nbsp;
                                            <button
                                              type="button"
                                              data-toggle="tooltip"
                                              title=""
                                              className="btn btn-primary"
                                              data-original-title="view"
                                              onClick={() => {
                                                setSelectedInvestment(item);
                                                setOpenInvestmentEditModal(
                                                  true
                                                );
                                              }}
                                            >
                                              <i className="fa fa-edit"></i>{" "}
                                            </button>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    )
                  ) : (
                    <div className="row card">
                      <div className="text-center message-box">
                        <p>No Investment found</p>
                      </div>
                    </div>
                  )}
                </div>

                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-end">
                    <li className="page-item">
                      <button
                        className="page-link"
                        onClick={() => changePage(-1)}
                      >
                        Previous
                      </button>
                    </li>
                    {[...Array(totalPages)].map((e, i) => (
                      <li className="page-item" key={i}>
                        <button
                          className={
                            i === currentPage
                              ? "page-link btn-primary text-white"
                              : "page-link"
                          }
                          onClick={() => changePage(i)}
                        >
                          {i + 1}
                        </button>
                      </li>
                    ))}
                    <li className="page-item">
                      <button
                        className="page-link"
                        onClick={() => changePage(+1)}
                      >
                        Next
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </OtherPageBody>
    </>
  );
};

export default ManageInvestment;
