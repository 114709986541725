const Status = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  SUSPENDED: "SUSPENDED",
  BLOCKED: "BLOCKED",
  RELEASED: "RELEASED",
  FAILED: "FAILED",
  SUCCESSFUL: "SUCCESSFUL",
  AVAILABLE: "AVAILABLE",
  UNAVAILABLE: "UNAVAILABLE",
  PENDING: "PENDING",
  INPROGRESS: "INPROGRESS",
  FOUND: "FOUND",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  UNDERREVIEW: "UNDERREVIEW",
  SETTLED: "SETTLED",
};

export { Status };
