import React, { useEffect, useState } from "react";
import App from "./App";
import { storage } from "./utils/constants/storage";
import { ToastContainer } from 'react-toastify';
const token = storage.get("userToken");
const userData = storage.get("user");

let auth = null;
if (userData != null) {
  auth = JSON.parse(userData);
  auth.token = token;
}
export const UserContext = React.createContext({
  user: auth,
  setUser: () => { }
});

function Access() {
  const [user, setUser] = useState(auth);
  const value = { user, setUser };

  useEffect(() => {
    const theme = storage.get('theme');
    if (theme && theme !== '' && theme !== undefined && theme != null) {
      document.querySelector('body').style = `filter:${theme}`;
    }
  }, [])

  return (
    <div className="Access">
      <ToastContainer />
      <UserContext.Provider value={value}>
        <App />
      </UserContext.Provider>
    </div>
  );
}

export default Access;
