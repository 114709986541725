import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "./components/button/Button";
import InputField from "./components/inputs/InputField";
import { auth } from "../../service/auth.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { messages } from "../../utils/constants/messages";
import { UserType } from "../../enums/UserType";
import { storage } from "../../utils/constants/storage";
import OptionField from "./components/select/OptionField";
import { location } from "../../utils/constants/location";
import { useEffect } from "react";
import { businessService } from "../../service/business.service";
import { titleCase } from "title-case";
import shape1 from "./../../assets/img/shape/vector-shape11.png";
import shape2 from "./../../assets/img/shape/shape2.png";
import shape3 from "./../../assets/img/shape/shape3.png";
import SignNavBar from "./components/SignNavBar";
import { useSearchParams } from "react-router-dom";
import TextArea from "./components/inputs/TextArea";

const SignUpBusiness = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [input, setInput] = useState({
    name: "",
    businessType: "",
    businessTarget: "",
    phoneNumber: "",
    emailAddress: "",
    password: "",
    confirmPassword: "",
    alias: "",
    state: "",
    lga: "",
    address: "",
    referee: searchParams.get("referee") ? searchParams.get("referee") : "",
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const [cities] = useState(location.getCities());
  const [area, setAreas] = useState(["Select area..."]);
  const [businessTypes, setBusinessTypes] = useState([
    "Select business type...",
  ]);
  const [businessTargets] = useState([
    "Select business target...",
    "Product",
    "Service",
    "General",
  ]);

  const getBusinessTypes = () => {
    businessService
      .getAllBusinessType()
      .then(async (result) => {
        if (result.data.success) {
          const types = result.data.data;
          if (types != null) {
            const typesData = ["Select business type..."];
            await types.forEach((type) =>
              typesData.push(titleCase(type.title.toLowerCase()))
            );
            setBusinessTypes(typesData);
          }
        }
      })
      .catch((ex) => toast.error("Unable to fetch business type"));
  };

  useEffect(() => {
    getBusinessTypes();
  }, []);

  const create = () => {
    if (input.name === null) toast.error("Enter your business name");
    else if (
      input.businessType === null ||
      input.businessType === "Select business type..."
    )
      toast.error("Select business type");
    else if (input.businessTarget === "Select business target...")
      toast.error("Select business target");
    else if (input.state === "Select location...")
      toast.error("Select business location");
    else if (input.lga === null || input.lga === "Select area...")
      toast.error("Select location area");
    else if (input.address === "") toast.error("Enter business address");
    else if (input.phoneNumber.length < 11)
      toast.error(messages.phoneLengthMessage);
    else if (input.password.length < 6)
      toast.error(messages.passwordLengthMessage);
    else if (input.confirmPassword !== input.password)
      toast.error(messages.passwordMisMatch);

    if (
      input.name !== null &&
      input.businessType !== null &&
      input.businessType !== "Select business type..." &&
      input.businessTarget !== "Select business target..." &&
      input.state !== "Select location..." &&
      input.lga !== null &&
      input.lga !== "Select area..." &&
      input.address !== "" &&
      input.phoneNumber.length >= 11 &&
      input.password.length >= 6 &&
      input.confirmPassword === input.password
    ) {
      let alias = input.name.replaceAll(" ", "");
      alias = alias.toLowerCase();

      const request = {
        name: input.name,
        accountType: UserType.Business,
        businessType: input.businessType,
        businessTarget: input.businessTarget,
        phoneNumber: input.phoneNumber,
        emailAddress: input.emailAddress,
        password: input.password,
        alias: alias,
        state: input.state,
        lga: input.lga,
        address: input.address,
        referee: Number(input.referee),
      };
      setIsProcessing(true);
      auth
        .registerUser(request)
        .then((result) => {
          setIsProcessing(false);
          if (result.data.success) {
            toast.success("Congratulations! you are good to go");
            storage.save("username", result.data.data.phoneNumber);
            // setTimeout(() => {
            //   navigate('/user/verify-account');
            // }, 500);
            setTimeout(() => {
              window.location = "/user/login";
            }, 500);
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((e) => {
          setIsProcessing(false);
          toast.error(e.response.data.message);
        });
    }
  };

  return (
    <div className="wrapper sign-body">
      <SignNavBar />
      <ToastContainer />
      <div className="page-title-area">
        <div className="container">
          <div className="page-title-content">
            <h2>Setup your Business Account</h2>
            <ul>
              <li>Already have an account?</li>
              &nbsp;
              <li>
                <i className="fa fa-pencil-square"></i>
                <Link to="/user/login">Signin</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="shape-img1">
          <img src={shape1} alt="images" />
        </div>
        <div className="shape-img2">
          <img src={shape2} alt="images" />
        </div>
        <div className="shape-img3">
          <img src={shape3} alt="images" />
        </div>
      </div>
      <div className="sign-container">
        <div className="rounded">
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <InputField
                  type="text"
                  value={input.name}
                  placeholder="Enter your Business name"
                  label="Business Name"
                  option="**"
                  name="name"
                  onChange={(e) => {
                    setInput({
                      ...input,
                      name: e.target.value,
                    });
                  }}
                  required
                />
              </div>

              <div className="col-md-6">
                <OptionField
                  value={input.businessType}
                  children={businessTypes}
                  placeholder="Select your Business Type"
                  label="Business Type"
                  name="businessType"
                  onChange={(e) =>
                    setInput({
                      ...input,
                      businessType: e.target.value,
                    })
                  }
                  required
                />
              </div>

              <div className="col-md-6">
                <InputField
                  type="tel"
                  value={input.phoneNumber}
                  placeholder="Enter your Business Phone Number"
                  label="Phone Number"
                  maxLength="11"
                  option="**"
                  name="phoneNumber"
                  onChange={(e) =>
                    setInput({
                      ...input,
                      phoneNumber: e.target.value,
                    })
                  }
                  required
                />
              </div>

              <div className="col-md-6">
                <InputField
                  type="email"
                  value={input.emailAddress}
                  placeholder="Enter your Email Address"
                  label="Email Address(Optional)"
                  name="emailAddress"
                  onChange={(e) =>
                    setInput({
                      ...input,
                      emailAddress: e.target.value,
                    })
                  }
                />
              </div>

              <div className="col-md-6">
                <OptionField
                  value={input.businessTarget}
                  children={businessTargets}
                  placeholder="Select your Business Target"
                  label="Business Target"
                  name="businessTarget"
                  onChange={(e) =>
                    setInput({
                      ...input,
                      businessTarget: e.target.value,
                    })
                  }
                  required
                />
              </div>

              <div className="col-md-6">
                <OptionField
                  value={input.state}
                  children={cities}
                  placeholder="Where is your Business Located?"
                  label="Business Location"
                  name="state"
                  onChange={(e) => {
                    setAreas(location.getArea(e.target.value));
                    setInput({
                      ...input,
                      state: e.target.value,
                    });
                  }}
                  required
                />
              </div>

              <div className="col-md-6">
                <OptionField
                  value={input.lga}
                  children={area}
                  placeholder="Local Government"
                  label="Location Area"
                  name="lga"
                  onChange={(e) =>
                    setInput({
                      ...input,
                      lga: e.target.value,
                    })
                  }
                  required
                />
              </div>

              <div className="col-md-6">
                <InputField
                  type="password"
                  value={input.password}
                  placeholder="Enter Password"
                  label="Password"
                  option="*"
                  name="password"
                  onChange={(e) =>
                    setInput({
                      ...input,
                      password: e.target.value,
                    })
                  }
                  required
                ></InputField>
              </div>
              <div className="col-md-6">
                <InputField
                  type="password"
                  value={input.confirmPassword}
                  placeholder="Enter Confirm Password"
                  label="Confirm Password"
                  option="*"
                  name="confirmPassword"
                  onChange={(e) =>
                    setInput({
                      ...input,
                      confirmPassword: e.target.value,
                    })
                  }
                  required
                />
              </div>

              <div className="col-md-6">
                <InputField
                  type="text"
                  value={input.referee}
                  maxLength="6"
                  placeholder="Who reffered you to fayib?"
                  label="Refferal Cod(Optional)"
                  name="referee"
                  disabled={searchParams.get("referee") ? true : false}
                  readonly={searchParams.get("referee") ? true : false}
                  onChange={(e) =>
                    setInput({
                      ...input,
                      referee: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="col-md-12">
              <TextArea
                type="text"
                value={input.address}
                placeholder="Business Full Address"
                label="Business Address"
                name="address"
                onChange={(e) =>
                  setInput({
                    ...input,
                    address: e.target.value,
                  })
                }
                required
              />
            </div>
            <Button
              isLoading={isProcessing}
              type="button"
              value={
                isProcessing ? messages.processingMessage : "Create Account"
              }
              name="button"
              onClick={() => (!isProcessing ? create() : null)}
              contain={true}
            />
          </div>
        </div>

        <div className="my-2 mb-5">
          <div className="text-center">
            {/* <Link className="" to="/user/register">
              Create Individual Account
            </Link> */}
            {/* &nbsp; &nbsp;&nbsp; Already have an account?{' '}
            <Link className="" to="/user/login">
              Login
            </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpBusiness;
