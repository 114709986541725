import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import { format } from "timeago.js";
import OtherPageBody from "./OtherPageBody";
import Loader from "./components/loaders/Loader";
import SearchField from "./components/inputs/SearchField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import toMoney from "number-to-money";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { UserContext } from "../../Access";
import { useContext } from "react";
import InputField from "./components/inputs/InputField";
import { depositService } from "../../service/deposit.service";
import TinyLoader from "./components/loaders/TinyLoader";
import { inventoryService } from "../../service/inventory.service";
import { useReactToPrint } from "react-to-print";
import { storage } from "../../utils/constants/storage";
import * as htmlToImage from "html-to-image";
import signature from "./../../assets/img/signature.png";
import { IconButton } from "@mui/material";
import { Cancel } from "@mui/icons-material";

const ManageDeposit = () => {
  const userSession = useContext(UserContext);
  const user = userSession.user;

  const [allProductsModal, setAllProductsModal] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [productsSelected, setProductsSelected] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [totalPayable, setTotalPayable] = useState(0);
  const [response, setResponse] = useState([]);
  const [inProgress, setInProgress] = useState(false);
  const [openRegisterDepositModal, setOpenRegisterDepositModal] =
    useState(false);
  const [openDeleteDepositModal, setOpenDeleteDepositModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [totalPages, setTotalPage] = useState();
  let [currentPage, setCurrentPage] = useState(0);
  const { width } = useWindowDimensions();
  const [input, setInput] = useState({ from: "", to: "" });
  const [registerDepositInput, setRegisterDepositInput] = useState({
    title: "",
    customerName: "",
    customerPhoneNumber: "",
    amount: "",
    products: null,
    description: "",
    fulfilmentDate: "",
  });
  const [registerDepositProgress, setRegisterDepositProgress] = useState(false);
  const [deleteProgress, setDeleteProgress] = useState(false);
  const [selectedDeposit, setSelectedDeposit] = useState(false);
  const [depositResult, setDepositResult] = useState(null);
  const [openReceiptModal, setOpenReceiptModal] = useState(true);
  const receiptRef = useRef();
  const [select, setSelect] = useState(
    Array.from({ length: allProducts.length }).fill(false)
  );

  const [printerType, setPrinterType] = useState(storage.get("printer"));
  const handlePrint = useReactToPrint({
    content: () => receiptRef.current,
  });

  const downloadImage = async () => {
    const dataUrl = await htmlToImage.toPng(receiptRef.current);
    const link = document.createElement("a");
    link.download = "receipt.png";
    link.href = dataUrl;
    link.click();
    setOpenReceiptModal(false);
  };

  const downloadAndShare = async () => {
    const dataUrl = await htmlToImage.toPng(receiptRef.current);
    const link = document.createElement("a");
    link.download = "receipt.png";
    link.href = `whatsapp://send?text=${dataUrl}`;
    link.click();
    setOpenReceiptModal(false);
  };

  const getAllDeposits = () => {
    const request = {
      from: input.from,
      to: input.to,
    };
    setInProgress(true);
    depositService
      .getAllDeposit(request, currentPage)
      .then((result) => {
        setInProgress(false);
        if (result.data.success) {
          setResponse(result.data.data.page);
        }
      })
      .catch((e) => {
        setResponse([]);
        setInProgress(false);
      });
  };

  const changePage = (action) => {
    if (action === -1) {
      if (currentPage > 0) currentPage--;
    } else if (action === +1) {
      if (currentPage < totalPages) currentPage++;
    } else {
      currentPage = action;
    }
    setCurrentPage(currentPage);
    if (searchText && searchText.length >= 3) performSearch();
    else getAllDeposits();
  };

  const performSearch = () => {
    if (searchText && searchText.length >= 3) {
      const request = {
        from: input.from,
        to: input.to,
      };
      setInProgress(true);
      depositService
        .searchDeposits(request, currentPage, searchText)
        .then((result) => {
          setInProgress(false);
          if (result.data.success) {
            const { page } = result.data.data;
            const { data } = result.data;
            setResponse(page);
            if (page.length > 0) {
              setTotalPage(data.totalPages);
              setCurrentPage(data.currentPage);
            }
          } else {
            setResponse([]);
          }
        })
        .catch((e) => {
          setResponse([]);
          setInProgress(false);
        });
    }
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
    performSearch();
  };

  const registerDeposit = () => {
    if (
      registerDepositInput.title === "" ||
      registerDepositInput.title.length < 3
    )
      toast.error("Provide title");
    else if (registerDepositInput.customerName === "")
      toast.error("Provide customer name");
    else if (
      registerDepositInput.customerPhoneNumber === "" ||
      registerDepositInput.customerPhoneNumber.length !== 11
    )
      toast.error("Provide valid phone number");
    else if (
      registerDepositInput.amount === "" ||
      registerDepositInput.amount <= 0
    )
      toast.error("Provide valid amount");
    else {
      const request = {
        title: registerDepositInput.title,
        amount: Number(registerDepositInput.amount),
        customerName: registerDepositInput.customerName,
        customerPhoneNumber: registerDepositInput.customerPhoneNumber,
        description: registerDepositInput.description,
        products: productsSelected
          ? productsSelected.map((elem) => ({
              puid: elem.puid,
              name: elem.title,
              type: elem.type,
              price: elem.sellingPrice,
              businessId: elem.businessId,
            }))
          : null,
        fulfilmentDate: registerDepositInput.fulfilmentDate,
      };
      setRegisterDepositProgress(true);
      depositService
        .createDeposit(request)
        .then((result) => {
          setRegisterDepositProgress(false);
          if (result.data.success) {
            setDepositResult(result.data.data);
            setOpenRegisterDepositModal(false);
            setOpenReceiptModal(true);
            getAllDeposits();
            toast.success("Deposited successfully");
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error(e.response.data.message);
          setRegisterDepositProgress(false);
        });
    }
  };

  const performDelete = () => {
    setDeleteProgress(true);
    depositService
      .deleteDeposit(selectedDeposit.duid)
      .then((result) => {
        setOpenDeleteDepositModal(false);
        setDeleteProgress(false);
        if (result.data.success) {
          getAllDeposits();
          toast.success("Deleted successfully");
        } else {
          toast.error(result.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error(e.response.data.message);
        setOpenDeleteDepositModal(false);
        setDeleteProgress(false);
      });
  };

  const getAllProducts = () => {
    setInProgress(true);
    inventoryService
      .getInventoryProducts(0)
      .then((result) => {
        setInProgress(false);
        if (result.data.success) {
          const { page } = result.data.data;
          setAllProducts(page);
        }
      })
      .catch((e) => {
        setInProgress(false);
      });
  };

  const handleDelete = (productID) => {
    setProductsSelected((prev) =>
      prev.filter((item) => item._id !== productID)
    );

    const product = productsSelected.find((item) => item._id === productID);
    if (product) {
      let tp = totalPayable - product.sellingPrice;
      setTotalPayable(tp);
    }
  };

  useEffect(() => {
    if (selectedProducts) {
      const isExist = productsSelected.find(
        (item) => item._id === selectedProducts._id
      );
      if (!isExist) {
        selectedProducts.totalPayable = selectedProducts.sellingPrice;
        setProductsSelected((prev) => [...prev, selectedProducts]);

        let tp = totalPayable;
        tp += selectedProducts.sellingPrice;
        setTotalPayable(tp);
      }
      setSelectedProducts(null);
    }
  }, [selectedProducts]);

  useEffect(() => {
    getAllDeposits();
    getAllProducts();
  }, []);

  return (
    <OtherPageBody>
      <ToastContainer />

      <Dialog open={openReceiptModal && depositResult !== null}>
        <DialogTitle>Receipt</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpenReceiptModal(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Cancel />
        </IconButton>
        <DialogContent>
          {depositResult ? (
            <div className="row">
              <div className="col-md-12">
                {printerType === "SMALL" ? (
                  <div className="panel  small-panel invoice" ref={receiptRef}>
                    <div className="panel-body p-3">
                      <div className="invoice-ribbon">
                        <div className="ribbon-inner  bg-primary">Paid</div>
                      </div>
                      <div className="row  p-5">
                        <div className="row">
                          <div className="col-12">
                            <h1 className="marginright">{user.business}</h1>
                            <span className="marginright">
                              Executed by {depositResult.createdBy}
                            </span>
                          </div>
                        </div>
                        <hr />
                        <div className="mt-3  mb-2">
                          <h2 className="marginright">
                            Deposited by {depositResult.customerName}
                          </h2>
                        </div>
                        {depositResult.products &&
                        depositResult.products.length > 0 ? (
                          <div className="row table-row">
                            <table className="table table-striped table-responsive">
                              <thead>
                                <tr>
                                  <th>Item</th>
                                  <th>Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                {depositResult.products.map((item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{item.name}</td>
                                      <td className="text-left">
                                        {toMoney(item.price)}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          <></>
                        )}
                        <div className="row">
                          <div className="col-sm-12 text-left pull-left invoice-total mt-5">
                            <h1>
                              Price
                              <br /> ₦{toMoney(depositResult.amount)}
                            </h1>
                          </div>
                          <div className="col-sm-12">
                            <img src={signature} alt="signature mt-3" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="panel panel-default invoice" ref={receiptRef}>
                    <div className="panel-body p-3">
                      <div className="invoice-ribbon">
                        <div className="ribbon-inner  bg-primary">Paid</div>
                      </div>
                      <div className="row  p-5">
                        <div className="row">
                          <div
                            className={
                              width > 800 ? "col-sm-6  mt-5" : "col-sm-6"
                            }
                          >
                            <h3 className="marginright">{user.business}</h3>
                            <span className="marginright">
                              {depositResult.createdBy}
                            </span>
                          </div>
                          <div
                            className={
                              width > 800
                                ? "col-sm-6  mt-5 top-right"
                                : "col-sm-6 top-right"
                            }
                          >
                            <h3 className="marginright">
                              REF-{depositResult.code}
                            </h3>
                            <span className="marginright">
                              {new Date(depositResult.createdAt).toISOString()}
                            </span>
                          </div>
                        </div>
                        <hr />
                        <div className="mt-5  mb-3">
                          <span className="marginright">
                            Deposited by{" "}
                            <strong>{depositResult.customerName}</strong> with
                            phone number{" "}
                            <strong>{depositResult.customerPhoneNumber}</strong>
                          </span>
                        </div>
                        {depositResult.products &&
                        depositResult.products.length > 0 ? (
                          <div className="row table-row">
                            <table className="table table-striped table-responsive">
                              <thead>
                                <tr>
                                  <th className="text-center">#</th>
                                  <th>Name</th>
                                  <th>Type</th>
                                  <th>Price</th>
                                </tr>
                              </thead>
                              <tbody>
                                {depositResult.products.map((item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td className="text-center">{++index}</td>
                                      <td>{item?.name}</td>
                                      <td className="text-right">
                                        {item?.type}
                                      </td>
                                      <td className="text-right">
                                        ₦
                                        {item?.price ? toMoney(item?.price) : 0}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          <></>
                        )}
                        <div className="row">
                          <div className="col-sm-6">
                            <img src={signature} alt="signature mt-3" />
                          </div>
                          <div className="col-sm-6 text-right pull-right invoice-total mt-5">
                            <p>
                              Amount Paid : ₦{toMoney(depositResult.amount)}
                            </p>
                            {depositResult.products &&
                            depositResult.products.length > 0 ? (
                              <p>
                                Balance : ₦
                                {toMoney(
                                  depositResult.products.reduce((prev, cur) => {
                                    return prev + cur.price;
                                  }, 0) - depositResult.amount
                                )}
                              </p>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="row">
              <p>Unable to generate receipt</p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          {width > 800 ? (
            <button
              className="btn btn-secondary btn-round mr-2"
              onClick={() => {
                downloadImage();
                setOpenReceiptModal(false);
                toast.success("Downloading...");
              }}
            >
              Download
            </button>
          ) : (
            <></>
          )}

          <button
            className="btn btn-primary btn-round mr-2"
            onClick={() => {
              handlePrint();
              setOpenReceiptModal(false);
              toast.success("Printing...");
            }}
          >
            Print
          </button>

          <button
            className="btn btn-danger  btn-round mr-2"
            onClick={() => {
              downloadAndShare();
            }}
          >
            Share
          </button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={allProductsModal}
        fullWidth="100px"
        style={{ width: "auto !important" }}
      >
        <DialogTitle>Select Products</DialogTitle>
        <DialogContent>
          {allProducts.length > 0 ? (
            <div className="popup-search">
              <SearchField
                value={searchText}
                placeholder="Search e.g Tecno or Honda"
                onChange={(e) => {
                  const q = e.target.value;
                  setSearchText(q);
                  if (q.length > 2) {
                    setTimeout(() => {
                      performSearch(q);
                    }, 300);
                  } else {
                    if (q.length <= 0) {
                      getAllProducts();
                    }
                  }
                }}
                onClick={() => {
                  performSearch(searchText);
                }}
              />
            </div>
          ) : (
            <></>
          )}
          <div className="table-striped table-responsive">
            {inProgress ? (
              <Loader />
            ) : allProducts.length > 0 ? (
              allProducts.map((product, index) => {
                return (
                  <button
                    key={index}
                    type="button"
                    data-toggle="tooltip"
                    title={product.title}
                    className={
                      select[index]
                        ? "btn default-btn  p-1 m-1"
                        : "btn default-btn btn-secondary p-1 m-1"
                    }
                    data-original-title="Edit Task"
                    onClick={() => {
                      //select and deselect function
                      if (select[index]) {
                        handleDelete(product._id);
                      } else {
                        setSelectedProducts({ ...product });
                      }
                      setSelect((prevState) => {
                        const selectArr = [...prevState];
                        selectArr[index] = !selectArr[index];
                        return selectArr;
                      });
                    }}
                  >
                    {select[index] ? <i className="fa fa-check"></i> : <></>}{" "}
                    {product.title}
                  </button>
                );
              })
            ) : (
              <div className="container text-center">
                <small className="disabled">No product found</small>
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <button
            className="btn btn-danger btn-border btn-round mr-2"
            onClick={() => {
              setAllProductsModal(false);
            }}
          >
            Okay
          </button>
        </DialogActions>
      </Dialog>
      <Dialog open={openRegisterDepositModal}>
        <DialogTitle>Register Deposit</DialogTitle>
        <DialogContent>
          <InputField
            type="text"
            name="title"
            value={registerDepositInput.title}
            placeholder="Deposit Title"
            label="Deposit Title"
            onChange={(e) =>
              setRegisterDepositInput({
                ...registerDepositInput,
                title: e.target.value,
              })
            }
            required
          />
          <InputField
            type="text"
            name="customerName"
            value={registerDepositInput.customerName}
            placeholder="Customer name"
            label="Customer Name"
            onChange={(e) =>
              setRegisterDepositInput({
                ...registerDepositInput,
                customerName: e.target.value,
              })
            }
            required
          />
          <InputField
            type="tel"
            maxLength="11"
            name="customerPhoneNumber"
            value={registerDepositInput.customerPhoneNumber}
            placeholder="Customer Phone Number"
            label="Customer Phon Number"
            onChange={(e) =>
              setRegisterDepositInput({
                ...registerDepositInput,
                customerPhoneNumber: e.target.value,
              })
            }
            required
          />
          <InputField
            type="number"
            name="amount"
            value={registerDepositInput.amount}
            placeholder="Amount"
            label="Amount"
            onChange={(e) =>
              setRegisterDepositInput({
                ...registerDepositInput,
                amount: e.target.value,
              })
            }
            required
          />
          <InputField
            type="date"
            name="fulfilmentDate"
            value={registerDepositInput.fulfilmentDate}
            placeholder="Expected Fulfilment Date"
            label="Fulfilment Date"
            onChange={(e) =>
              setRegisterDepositInput({
                ...registerDepositInput,
                fulfilmentDate: e.target.value,
              })
            }
          />
          <InputField
            type="text"
            name="description"
            value={registerDepositInput.description}
            placeholder="Description"
            label="Remark"
            onChange={(e) =>
              setRegisterDepositInput({
                ...registerDepositInput,
                description: e.target.value,
              })
            }
          />
          <InputField
            style={{ textAlign: "left" }}
            type="button"
            name="products"
            value={
              productsSelected.length > 0
                ? productsSelected.length > 1
                  ? `${productsSelected.length} item selected`
                  : productsSelected[0]?.title || "Select Product"
                : "Select Product"
            }
            label="Attach Products"
            onClick={(e) => setAllProductsModal(true)}
          />
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => setOpenRegisterDepositModal(false)}
            className="btn btn-primary btn-border btn-round mr-2"
          >
            Cancel
          </button>
          <button
            onClick={!registerDepositProgress ? registerDeposit : null}
            className="btn btn-primary btn-round"
          >
            {registerDepositProgress ? <TinyLoader /> : "Continue"}
          </button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDeleteDepositModal}>
        <DialogTitle>Delete Deposit</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this Deposit?</p>
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => {
              setOpenDeleteDepositModal(false);
            }}
            className="btn btn-danger btn-border btn-round mr-2"
          >
            No
          </button>
          <button
            onClick={!deleteProgress ? performDelete : null}
            className="btn btn-primary btn-round"
          >
            {deleteProgress ? <TinyLoader /> : "Yes"}
          </button>
        </DialogActions>
      </Dialog>

      <div className="page-inner">
        <div className="row">
          <div className="col-md-12">
            <div className="header-card">
              <div className="card-header">
                <div className="d-flex align-items-center">
                  <h4 className="card-title">All Deposits</h4>
                  <SearchField
                    value={searchText}
                    placeholder="Search Depositor name e.g Aisha"
                    onChange={onSearch}
                    onClick={performSearch}
                  />
                  <button
                    onClick={() => setOpenRegisterDepositModal(true)}
                    className="btn btn-primary  btn-round ml-auto btn-large link-btn"
                  >
                    <i className="fa fa-plus" style={{ margin: "5px" }}></i>
                    Register Deposit
                  </button>
                </div>
              </div>
            </div>
            <div className="filter-container d-flex align-item-center text-align-center">
              <InputField
                type="date"
                name="from"
                value={input.from}
                placeholder="From"
                label="From"
                onChange={(e) =>
                  setInput({
                    ...input,
                    from: e.target.value,
                  })
                }
              />
              <InputField
                type="date"
                name="to"
                value={input.to}
                placeholder="To"
                label="To"
                onChange={(e) =>
                  setInput({
                    ...input,
                    to: e.target.value,
                  })
                }
              />
              <div className="filter-button text-align-right">
                <button
                  type="button"
                  onClick={() => {
                    getAllDeposits();
                  }}
                  className="btn default-btn btn-primary btn-large p-2"
                >
                  Filter
                </button>
              </div>
            </div>
            <div className="row data-wrapper">
              {inProgress ? (
                <div className="row card">
                  <Loader></Loader>
                </div>
              ) : response.length > 0 ? (
                width > 800 ? (
                  <div className="row card">
                    <div className="card-body">
                      <table id="add-row" className=" table table-striped">
                        <thead>
                          <tr>
                            <th>S/n</th>
                            <th>Customer</th>
                            <th>Telephone</th>
                            <th>Paid Amount</th>
                            <th>Balance</th>
                            <th>Products</th>
                            <th>Fullfillment</th>
                            <th>Collected By</th>
                            <th>Deposited</th>
                          </tr>
                        </thead>

                        <tbody>
                          {response.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{++index}</td>
                                <td>{item.customerName}</td>
                                <td className="text-success">
                                  <a href={"tel:" + item.customerPhoneNumber}>
                                    <i className="fa fa-phone"></i>{" "}
                                    {item.customerPhoneNumber}
                                  </a>
                                </td>
                                <td>₦{toMoney(item.amount)}</td>
                                {item.products && item.products.length > 0 ? (
                                  <td>
                                    ₦
                                    {toMoney(
                                      item.products.reduce((prev, cur) => {
                                        return prev + cur.price;
                                      }, 0) - item.amount
                                    )}
                                  </td>
                                ) : (
                                  <td>N/A</td>
                                )}
                                <td>
                                  {item.products && item.products.length > 0
                                    ? item.products.map(
                                        (product) => "|" + product.name
                                      )
                                    : "Not sure"}
                                </td>
                                <td className="text-success">
                                  {item.fulfilmentDate
                                    ? format(item.fulfilmentDate)
                                    : "N/A"}
                                </td>
                                <td>{item.createdBy}</td>
                                <td>{format(item.createdAt)}</td>
                                <td>
                                  <div className="form-button-action">
                                    <button
                                      type="button"
                                      data-toggle="tooltip"
                                      title=""
                                      className="btn  btn-primary btn-outline bg-danger"
                                      data-original-title="repay"
                                      onClick={() => {
                                        setSelectedDeposit(item);
                                        setOpenDeleteDepositModal(true);
                                      }}
                                    >
                                      <i className="fa fa-times"></i>
                                    </button>
                                    &nbsp;
                                    <button
                                      type="button"
                                      data-toggle="tooltip"
                                      title=""
                                      className="btn  btn-primary "
                                      data-original-title="repay"
                                      onClick={() => {
                                        setDepositResult(item);
                                        setOpenReceiptModal(true);
                                      }}
                                    >
                                      <i className="fa fa-print"></i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  response.map((item, index) => {
                    return (
                      <div
                        className="list-card-item col-xl-2 col-lg-4 col-md-4 col-12"
                        key={index}
                      >
                        <div className="image-flip">
                          <div className="mainflip flip-0">
                            <div className="frontside">
                              <div className="card">
                                <div className="invoice-ribbon">
                                  <div className="ribbon-inner  bg-primary">
                                    {"Paid"}
                                  </div>
                                </div>
                                <div className="card-body text-left">
                                  <h4 className="card-title text-primary">
                                    {item.name}
                                  </h4>
                                  <ul className="card-text">
                                    <li>
                                      <h2>
                                        <strong>{item.customerName}</strong>
                                      </h2>
                                    </li>
                                    <li>
                                      <h2>
                                        <strong className="text-success">
                                          <i className="fa fa-phone"></i>{" "}
                                          <a
                                            href={
                                              "tel:" + item.customerPhoneNumber
                                            }
                                          >
                                            {item.customerPhoneNumber}
                                          </a>
                                        </strong>
                                      </h2>
                                    </li>
                                    <li>
                                      Paid Amount:{" "}
                                      <span className="text-danger">
                                        ₦{toMoney(item.amount)}
                                      </span>
                                    </li>

                                    {item.products &&
                                    item.products.length > 0 ? (
                                      <li>
                                        Balance:{" "}
                                        <span className="text-danger">
                                          ₦
                                          {toMoney(
                                            item.products.reduce(
                                              (prev, cur) => {
                                                return prev + cur.price;
                                              },
                                              0
                                            ) - item.amount
                                          )}
                                        </span>
                                      </li>
                                    ) : (
                                      <>N/A</>
                                    )}

                                    <li>
                                      Products:{" "}
                                      <span className="text-danger">
                                        {item.products
                                          ? item.products.map(
                                              (product) => "|" + product.name
                                            )
                                          : "Not sure"}
                                      </span>
                                    </li>
                                    <li>
                                      Collected By:{" "}
                                      <span className="text-primary">
                                        {item.createdBy || "Not sure"}
                                      </span>
                                    </li>
                                    <li>
                                      Fulfilment Date :
                                      <span className="text-primary">
                                        {item.fulfilmentDate || "Not sure"}
                                      </span>
                                    </li>
                                    <li>
                                      Deposited:
                                      <span className="text-primary">
                                        {format(item.createdAt)}
                                      </span>
                                    </li>
                                  </ul>

                                  <div className="form-button-action">
                                    <button
                                      type="button"
                                      data-toggle="tooltip"
                                      title=""
                                      className="btn btn-primary bg-danger"
                                      data-original-title="view"
                                      onClick={() => {
                                        setSelectedDeposit(item);
                                        setOpenDeleteDepositModal(true);
                                      }}
                                    >
                                      <i className="fa fa-times"></i> Delete
                                    </button>
                                    &nbsp;
                                    <button
                                      type="button"
                                      data-toggle="tooltip"
                                      title=""
                                      className="btn btn-primary"
                                      data-original-title="view"
                                      onClick={() => {
                                        setDepositResult(item);
                                        setOpenReceiptModal(true);
                                      }}
                                    >
                                      <i className="fa fa-print"></i> View
                                      Receipt
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )
              ) : (
                <div className="row card">
                  <div className="text-center message-box">
                    <p>No Deposit found</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-end">
            <li className="page-item">
              <button className="page-link" onClick={() => changePage(-1)}>
                Previous
              </button>
            </li>
            {[...Array(totalPages)].map((e, i) => (
              <li className="page-item" key={i}>
                <button
                  className={
                    i === currentPage
                      ? "page-link btn-primary text-white"
                      : "page-link"
                  }
                  onClick={() => changePage(i)}
                >
                  {i + 1}
                </button>
              </li>
            ))}
            <li className="page-item">
              <button className="page-link" onClick={() => changePage(+1)}>
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </OtherPageBody>
  );
};

export default ManageDeposit;
