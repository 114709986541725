import React, { useContext, useEffect } from "react";
import OtherPageBody from "./OtherPageBody";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import Loader from "./components/loaders/Loader";
import { ToastContainer, toast } from "react-toastify";
import { format } from "timeago.js";
import { titleCase } from "title-case";
import Switch from "react-switch";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TinyLoader from "./components/loaders/TinyLoader";
import { investmentService } from "../../service/investmen.service";
import { util } from "../../utils/constants/constants";
import toMoney from "number-to-money";
import { repaymentService } from "../../service/repayment.service";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { UserContext } from "../../Access";
import InputField from "./components/inputs/InputField";
import { IconButton } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { disbursalService } from "../../service/disbursal.service";
import { Status } from "../../enums/Status";
import { closePaymentModal, useFlutterwave } from "flutterwave-react-v3";

const InvestmentDetail = () => {
  const userSession = useContext(UserContext);
  const user = userSession.user;

  const { id } = useParams();
  const [investment, setInvestment] = useState();
  const [payments, setPayments] = useState();
  const [disbursal, setDisbursal] = useState();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [openDisbursalModal, setOpenDisbursalModal] = useState(false);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [openReturnInvestmentModal, setOpenReturnInvestmentModal] =
    useState(false);
  const [inProgress, setInProgress] = useState(true);
  const [paymentProgress, setPaymentProgress] = useState(false);
  const [disbursalProgress, setDisbursalProgress] = useState(false);
  const [deleteProgress, setDeleteProgress] = useState(false);
  const [approveProgress, setApproveProgress] = useState(false);
  const [rejectProgress, setRejectProgress] = useState(false);
  const [returnInvestmentProgress, setReturnInvestmentProgress] =
    useState(false);
  const { width } = useWindowDimensions();
  const [disbursalInput, setDisbursalInput] = useState({
    iuid: "",
    amount: "",
    paymentReferenceNumber: "",
  });
  const [paymentInput, setPaymentInput] = useState({
    iuid: "",
    amount: "",
    paymentReferenceNumber: "",
  });
  const [returnInvestmentInput, setReturnInvestmentInput] = useState({
    iuid: "",
    amount: "",
    paymentReferenceNumber: "",
  });

  const paymentConfig = {
    public_key: util.flutterwaveKey,
    tx_ref: util.getRandomString(),
    amount: 0,
    currency: "NGN",
    customer: {
      email: "support@fayib.com",
      phone_number: user.phoneNumber,
      name: user.name,
    },
    customizations: {
      title: "Fayib payment",
      description: "Payment to Fayib",
      logo: "https://fayib.com/img/icon.png",
    },
  };
  const handleFlutterPayment = useFlutterwave(paymentConfig);

  const performDelete = () => {
    if (investment) {
      setDeleteProgress(true);
      investmentService
        .deleteInvestment(investment.iuid)
        .then((result) => {
          setOpenDeleteModal(false);
          setDeleteProgress(false);
          if (result.data.success) {
            toast.success("Deleted successful");
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error(e.response.data.message);
          setOpenDeleteModal(false);
          setDeleteProgress(false);
        });
    } else {
      toast.error("No investment selected");
    }
  };

  const fetchInvestmentDetails = () => {
    setInProgress(true);
    investmentService
      .getInvestmentById(id)
      .then((result) => {
        console.log(result);
        setInProgress(false);
        if (result.data.success) {
          const investmentData = result.data.data;
          console.log(investmentData);
          setInvestment(investmentData);
        }
      })
      .catch((e) => {
        setInProgress(false);
      });
  };

  const fetchPayments = () => {
    setPaymentProgress(true);
    repaymentService
      .getAllRepaymentByInvestmentId(0, id)
      .then((result) => {
        setPaymentProgress(false);
        if (result.data.success) {
          const data = result.data.data;
          setDisbursal(data);
        }
      })
      .catch((e) => {
        setPaymentProgress(false);
      });
  };

  const fetchDisbursal = () => {
    setDisbursalProgress(true);
    disbursalService
      .getDisbursalByInvestmentId(id)
      .then((result) => {
        console.log(result);
        setDisbursalProgress(false);
        if (result.data.success) {
          const data = result.data.data;
          setDisbursal(data);
        }
      })
      .catch((e) => {
        setDisbursalProgress(false);
      });
  };

  const createDisbursal = () => {
    setDisbursalProgress(true);
    disbursalService
      .createDisbursal(disbursalInput)
      .then((result) => {
        setDisbursalProgress(false);
        if (result.data.success) {
          toast.success("Disbursed sucessfully");
          setOpenDisbursalModal(false);
          fetchDisbursal();
        } else {
          toast.error(result.data.message);
        }
      })
      .catch((e) => {
        setDisbursalProgress(false);
      })
      .finally((e) => {
        setDisbursalProgress(false);
      });
  };
  const returnInvestment = () => {
    if (
      returnInvestmentInput.amount != "" &&
      returnInvestmentInput.amount > 0
    ) {
      paymentConfig.paymentRef = util.getRandomString();
      paymentConfig.amount = returnInvestmentInput.amount;
      paymentConfig.customizations.title = "Return investment";
      paymentConfig.customizations.description =
        "Return invested amount to Fayib";

      console.log("paymentConfig:", paymentConfig);

      setReturnInvestmentProgress(true);
      handleFlutterPayment({
        callback: (response) => {
          setReturnInvestmentProgress(false);
          if (response.status && response.status === "completed") {
            console.log(response);
            toast.success("Payment successful");
          } else {
            toast.error(response.status);
          }
          closePaymentModal();
        },
        onClose: () => {
          setReturnInvestmentProgress(false);
          toast.error("Payment canceled");
        },
      });
    } else {
      toast.warn("Valid Amount required!");
    }
  };
  const payIncome = () => {
    if (paymentInput.amount !== "" && paymentInput.amount > 0) {
      paymentConfig.paymentRef = util.getRandomString();
      paymentConfig.amount = paymentInput.amount;
      paymentConfig.customizations.title = "Income Payment";
      paymentConfig.customizations.description = "Income payment to Fayib";

      setPaymentProgress(true);
      handleFlutterPayment({
        callback: (response) => {
          setPaymentProgress(false);
          if (response.status && response.status === "completed") {
            const request = {
              iuid: id,
              amount: paymentConfig.amount,
              paymentReferenceNumber: paymentConfig.paymentRef,
            };
            toast.success("Payment successful");
            setPaymentProgress(true);
            closePaymentModal();
            repaymentService
              .createRepayment(request)
              .then((result) => {
                setPaymentProgress(false);
                if (result.data.success) {
                  toast.success("Request successully");
                  setOpenPaymentModal(false);
                  fetchInvestmentDetails();
                  fetchPayments();
                } else {
                  toast.error(result.data.message);
                }
              })
              .catch((e) => {
                setOpenPaymentModal(false);
                setPaymentProgress(false);
              })
              .finally((e) => {
                setPaymentProgress(false);
              });
          } else {
            toast.error(response.status);
          }
         
        },
        onClose: () => {
          setPaymentProgress(false);
          toast.error("Payment canceled");
        },
      });
    } else {
      toast.warn("Valid Amount required!");
    }
  };
  const approveInvestment = () => {
    setApproveProgress(true);
    investmentService
      .updateInvestment(id, { status: Status.APPROVED })
      .then((result) => {
        setApproveProgress(false);
        if (result.data.success) {
          toast.success("Approved successully");
          setOpenApproveModal(false);
          fetchInvestmentDetails();
        }
      })
      .catch((e) => {
        setApproveProgress(false);
      })
      .finally((e) => {
        setApproveProgress(false);
      });
  };

  const rejectInvestment = () => {
    setRejectProgress(true);
    investmentService
      .updateInvestment(id, { status: Status.REJECTED })
      .then((result) => {
        setRejectProgress(false);
        if (result.data.success) {
          toast.success("Rejected successully");
          setOpenRejectModal(false);
          fetchInvestmentDetails();
        }
      })
      .catch((e) => {
        setRejectProgress(false);
      })
      .finally((e) => {
        setRejectProgress(false);
      });
  };

  useEffect(() => {
    fetchInvestmentDetails();
    fetchDisbursal();
    fetchPayments();
  }, []);

  return (
    <OtherPageBody>
      <ToastContainer />
      <Dialog open={openDeleteModal}>
        <DialogTitle>Delete Investment</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this investment?</p>
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => {
              setOpenDeleteModal(false);
            }}
            className="btn btn-danger btn-border btn-round mr-2"
          >
            No
          </button>
          <button
            onClick={!deleteProgress ? performDelete : null}
            className="btn btn-primary btn-round"
          >
            {deleteProgress ? <TinyLoader /> : "Yes"}
          </button>
        </DialogActions>
      </Dialog>

      <Dialog open={openApproveModal}>
        <DialogTitle>Approve Investment</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to approve this investment?</p>
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => {
              setOpenApproveModal(false);
            }}
            className="btn btn-danger btn-border btn-round mr-2"
          >
            No
          </button>
          <button
            onClick={!approveProgress ? approveInvestment : null}
            className="btn btn-primary btn-round"
          >
            {approveProgress ? <TinyLoader /> : "Yes"}
          </button>
        </DialogActions>
      </Dialog>

      <Dialog open={openRejectModal}>
        <DialogTitle>Reject Investment</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to reject this investment?</p>
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => {
              setOpenRejectModal(false);
            }}
            className="btn btn-danger btn-border btn-round mr-2"
          >
            No
          </button>
          <button
            onClick={!rejectProgress ? rejectInvestment : null}
            className="btn btn-primary btn-round"
          >
            {rejectProgress ? <TinyLoader /> : "Yes"}
          </button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDisbursalModal}>
        <DialogTitle>Disburse Funds</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpenDisbursalModal(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Cancel />
        </IconButton>
        <DialogContent>
          <InputField
            type="number"
            name="amount"
            value={disbursalInput.amount}
            placeholder="Investment Amount"
            label="Amount"
            disabled
            onChange={(e) =>
              setDisbursalInput({
                ...disbursalInput,
                amount: e.target.value,
              })
            }
            required
          />
          <InputField
            type="text"
            name="paymentReferenceNumber"
            value={disbursalInput.paymentReferenceNumber}
            placeholder="Payment Reference Number"
            label="Payment Reference Number"
            onChange={(e) =>
              setDisbursalInput({
                ...disbursalInput,
                paymentReferenceNumber: e.target.value,
              })
            }
          />
        </DialogContent>
        <DialogActions>
          <button
            onClick={!disbursalProgress ? createDisbursal : null}
            className="btn btn-primary btn-round"
          >
            {disbursalProgress ? <TinyLoader /> : "Continue"}
          </button>
        </DialogActions>
      </Dialog>

      <Dialog open={openPaymentModal}>
        <DialogTitle>Pay Income</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpenPaymentModal(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Cancel />
        </IconButton>
        <DialogContent>
          <InputField
            type="number"
            name="amount"
            value={paymentInput.amount}
            placeholder="Investment Amount"
            label="Amount"
            onChange={(e) =>
              setDisbursalInput({
                ...paymentInput,
                amount: e.target.value,
              })
            }
            required
          />
        </DialogContent>
        <DialogActions>
          <button
            onClick={!paymentProgress ? payIncome : null}
            className="btn btn-primary btn-round"
          >
            {paymentProgress ? <TinyLoader /> : "Continue"}
          </button>
        </DialogActions>
      </Dialog>

      <Dialog open={openReturnInvestmentModal}>
        <DialogTitle>Return Investment</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpenReturnInvestmentModal(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Cancel />
        </IconButton>
        <DialogContent>
          <InputField
            type="number"
            name="amount"
            value={returnInvestmentInput.amount}
            placeholder="Amount"
            label="Amount"
            onChange={(e) =>
              setDisbursalInput({
                ...returnInvestmentInput,
                amount: e.target.value,
              })
            }
            required
          />
        </DialogContent>
        <DialogActions>
          <button
            onClick={!returnInvestmentProgress ? returnInvestment : null}
            className="btn btn-primary btn-round"
          >
            {returnInvestmentProgress ? <TinyLoader /> : "Pay Now"}
          </button>
        </DialogActions>
      </Dialog>

      <div className="page-inner">
        <div className="header-card">
          <div className="card-header">
            <div className="d-flex align-items-center">
              <h4 className="card-title">Investment Details</h4>

              <button className="btn btn-primary btn-round ml-auto">
                <i className="fa fa-plus"></i>
                <Link
                  className="btn btn-primary btn-round ml-auto"
                  to="/investment/add"
                >
                  Make Payment
                </Link>
              </button>
            </div>
          </div>
        </div>
        {inProgress ? (
          <Loader />
        ) : investment ? (
          <div className="row mb-5">
            <div className="row">
              <div className="col-md-4">
                <div className="card ">
                  <div className="card-body">
                    <div className="meta">
                      <div className="data">
                        <h4 className="title">Investment Information</h4>
                      </div>
                    </div>
                    <ul className="data-ul">
                      <li>
                        <label>Id</label>
                        <p className="code-text">#IR{investment.code}</p>
                      </li>
                      <li>
                        <label>Business</label>
                        <p>{investment.businessName}</p>
                      </li>
                      <li>
                        <label>Owner</label>
                        <p className="text-success code-text">
                          {investment.ownerName}
                        </p>
                      </li>
                      <li>
                        <label>Owner Telephone</label>
                        <p className="text-success code-text">
                          {investment.ownerPhoneNumber}
                        </p>
                      </li>
                      <li>
                        <label>Amount</label>
                        <p className="text-success code-text">
                          ₦{toMoney(investment.amount)}
                        </p>
                      </li>
                      <li>
                        <label>Repayment</label>
                        <p className="text-success code-text">
                          ₦{toMoney(investment.repaymentAmount)}/
                          {investment.revenueSharingMethod}
                        </p>
                      </li>
                      <li>
                        <label>Payment Date</label>
                        <p>
                          {investment.repaymentStartDate.substr(
                            0,
                            investment.repaymentStartDate.lastIndexOf("-") + 3
                          )}
                        </p>
                      </li>
                      <li>
                        <label>Requested</label>
                        <p>{format(investment.createdAt)}</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card ">
                  <div className="card-body">
                    <div className="meta">
                      <div className="data">
                        <h4 className="title">Account Information</h4>
                      </div>
                    </div>
                    <ul className="data-ul">
                      <li>
                        <label>Bank Name</label>
                        <p>{investment.bankName}</p>
                      </li>
                      <li>
                        <label>Account Name</label>
                        <p className="text-success code-text">
                          {investment.accountName}
                        </p>
                      </li>
                      <li>
                        <label>Account Number</label>
                        <p className="text-success code-text">
                          {investment.accountNumber}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body">
                    <div className="text">
                      <ul className="data-ul">
                        <li>
                          <label>Investment Number</label>
                          <p>{investment.iuid}</p>
                        </li>

                        <li>
                          <label>Status</label>
                          <p
                            className={
                              investment.status === "ACTIVE"
                                ? "text-success"
                                : "text-danger"
                            }
                          >
                            {titleCase(investment.status)}
                          </p>
                        </li>
                      </ul>
                      <div className="action-wrapper mb-3">
                        <button
                          type="button"
                          data-toggle="tooltip"
                          title=""
                          className="btn  btn-primary"
                          onClick={() => {
                            setDisbursalInput({
                              ...disbursalInput,
                              amount: investment.amount,
                              iuid: investment.iuid,
                            });
                            setOpenDisbursalModal(true);
                          }}
                        >
                          <i className="fa  fa-plus"></i> Disburse
                        </button>
                        <button
                          type="button"
                          data-toggle="tooltip"
                          title=""
                          className="btn  btn-primary"
                          onClick={() => {
                            setPaymentInput({
                              ...paymentInput,
                              amount: investment.repaymentAmount,
                              iuid: investment.iuid,
                            });
                            setOpenPaymentModal(true);
                          }}
                        >
                          <i className="fa  fa-plus"></i> Pay Income
                        </button>
                        <button
                          type="button"
                          data-toggle="tooltip"
                          title=""
                          className="btn  btn-primary "
                          data-original-title="Transfer investment"
                          onClick={() => {
                            setReturnInvestmentInput({
                              ...returnInvestmentInput,
                              amount: investment.amount,
                              iuid: investment.iuid,
                            });
                            setOpenReturnInvestmentModal(true);
                          }}
                        >
                          <i className="fa  fa-reply-all"></i> Return Investment
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            setOpenRejectModal(true);
                          }}
                          data-toggle="tooltip"
                          className="btn  btn-danger"
                        >
                          <i className="fa fa-times"></i> Reject
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            setOpenApproveModal(true);
                          }}
                          data-toggle="tooltip"
                          className="btn  btn-success"
                        >
                          <i className="fa fa-check"></i> Approve
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            setOpenDeleteModal(true);
                          }}
                          data-toggle="tooltip"
                          className="btn  btn-danger"
                        >
                          <i className="fa fa-times"></i> Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="meta">
                        <div className="data">
                          <h4 className="title">Guarantor Details</h4>
                        </div>
                      </div>
                      <ul className="data-ul">
                        <li>
                          <label>Name</label>
                          <p>{investment.guarantorName}</p>
                        </li>
                        <li>
                          <label>Telephone</label>
                          <p>{investment.guarantorPhoneNumber}</p>
                        </li>
                        <li>
                          <label>{investment.guarantorIdCardType}</label>
                          <Link
                            to={investment.guarantorIdCard}
                            target="_blank"
                            download={
                              "fayib" +
                              investment.guarantorIdCard.substring(
                                investment.guarantorIdCard.lastIndexOf(".")
                              )
                            }
                          >
                            <img
                              src={`${util.storageURL}/${investment.guarantorIdCard}`}
                              alt="receipt"
                              className="ownership-proof-doc"
                            />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-md-8">
                  <div className="card">
                    <div className="card-body">
                      <div className="meta">
                        <div className="data">
                          <h4 className="title">Referees Information</h4>
                        </div>
                      </div>
                      <ul className="data-ul">
                        <li>
                          <label>First Referee</label>
                          <p> {investment.firstRefereeName} </p>
                        </li>
                        <li>
                          <label>Telephone</label>
                          <p> {investment.firstRefereePhoneNumber} </p>
                        </li>
                        <li>
                          <label>Relationship</label>
                          <p> {investment.firstRefereeRelationship} </p>
                        </li>
                      </ul>
                      <hr />
                      <ul className="data-ul">
                        <li>
                          <label>Second Referee</label>
                          <p> {investment.secondRefereeName} </p>
                        </li>
                        <li>
                          <label>Telephone</label>
                          <p> {investment.secondRefereePhoneNumber} </p>
                        </li>
                        <li>
                          <label>Relationship</label>
                          <p> {investment.secondRefereeRelationship} </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-md-4">
                    <div className="card">
                      {disbursal ? (
                        <div className="card-body">
                          <div className="meta">
                            <div className="data">
                              <h4 className="title">Disbursal Details</h4>
                            </div>
                          </div>
                          <ul className="data-ul">
                            <li>
                              <label>Payment Reference</label>
                              <p>
                                {disbursal.paymentReferenceNumber
                                  ? "#" +
                                    disbursal.paymentReferenceNumber.substring(
                                      0,
                                      10
                                    ) +
                                    ".."
                                  : "N/A"}
                              </p>
                            </li>
                            <li>
                              <label>Amount</label>
                              <p>₦{toMoney(disbursal.amount)}</p>
                            </li>
                            <li>
                              <label>Status</label>
                              <p className="text-success">{disbursal.status}</p>
                            </li>
                            <li>
                              <label>Disbursed</label>
                              <p>{format(disbursal.createdAt)}</p>
                            </li>
                          </ul>
                        </div>
                      ) : (
                        <div className="text-center message-box">
                          <p>Not disbursed yet</p>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-8">
                    {paymentProgress ? (
                      <div className="card">
                        <Loader></Loader>
                      </div>
                    ) : payments && payments.length > 0 ? (
                      width > 800 ? (
                        <div className="row card">
                          <div className="card-body">
                            <div className="table-responsive table-hover">
                              <table
                                id="add-row"
                                className="table table-striped"
                              >
                                <thead>
                                  <tr>
                                    <th>S/n</th>
                                    <th>Id</th>
                                    <th>Business</th>
                                    <th>Telephone</th>
                                    <th>Amount</th>
                                    <th>Return Amount</th>
                                    <th>Status</th>
                                    <th>Payment Date</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {payments.map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{++index}</td>
                                        <td>
                                          <Link
                                            to={`/investment/details/${item.iuid}`}
                                          >
                                            #IR-{item.code}
                                          </Link>
                                        </td>
                                        <td>{item.businessName}</td>
                                        <td>{item.ownerPhoneNumber}</td>
                                        <td>₦{toMoney(item.amount)}</td>
                                        <td>
                                          ₦{toMoney(item.repaymentAmount)} /
                                          {titleCase(
                                            item.revenueSharingMethod.toLowerCase()
                                          )}
                                        </td>
                                        <td className={item.status}>
                                          {item.status}
                                        </td>
                                        <td>
                                          {item.status !== "UNDERREVIEW"
                                            ? format(item.repaymentStartDate)
                                            : "PENDING"}
                                        </td>
                                        <td>
                                          {user.role === "ADMIN" ? (
                                            <div className="form-button-action">
                                              <button
                                                type="button"
                                                data-toggle="tooltip"
                                                title=""
                                                className="btn btn-primary bg-danger"
                                                data-original-title="view"
                                                onClick={() => {}}
                                              >
                                                <i className="fa fa-times"></i>{" "}
                                                Reject
                                              </button>
                                              &nbsp;
                                              <button
                                                type="button"
                                                data-toggle="tooltip"
                                                title=""
                                                className="btn btn-primary"
                                                data-original-title="view"
                                                onClick={() => {}}
                                              >
                                                <i className="fa fa-check"></i>{" "}
                                                Confirmed
                                              </button>
                                            </div>
                                          ) : (
                                            <>
                                              {" "}
                                              <div className="form-button-action">
                                                <button
                                                  type="button"
                                                  data-toggle="tooltip"
                                                  title=""
                                                  className="btn btn-primary"
                                                  data-original-title="view"
                                                  onClick={() => {}}
                                                >
                                                  <i className="fa fa-check"></i>{" "}
                                                </button>
                                              </div>
                                            </>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      ) : (
                        payments.map((item, index) => {
                          return (
                            <div
                              className="list-card-item col-xl-2 col-lg-4 col-md-4 col-12"
                              key={index}
                            >
                              <div className="image-flip">
                                <div className="mainflip flip-0">
                                  <div className="frontside">
                                    <div className="card">
                                      <div className="invoice-ribbon">
                                        <div
                                          style={{ fontSize: "12px" }}
                                          className={`ribbon-inner  ${
                                            item.clearedAmount >= item.amount
                                              ? "bg-primary"
                                              : "bg-danger"
                                          }`}
                                        >
                                          {titleCase(item.status.toLowerCase())}
                                        </div>
                                      </div>
                                      <div className="card-body text-left">
                                        <h4 className="card-title text-primary">
                                          {item.businessName}
                                        </h4>
                                        <ul className="card-text">
                                          <li>
                                            <h2>
                                              <strong>{item.ownerName}</strong>
                                            </h2>
                                          </li>
                                          <li>
                                            <h2>
                                              <strong>
                                                Tel: {item.ownerPhoneNumber}
                                              </strong>
                                            </h2>
                                          </li>

                                          <li>
                                            Total Amount:{" "}
                                            <span className="text-danger">
                                              ₦{toMoney(item.amount)}
                                            </span>
                                          </li>
                                          <li>
                                            Return Amount:{" "}
                                            <span className="text-danger">
                                              ₦{toMoney(item.repaymentAmount)}
                                            </span>
                                            /
                                            <span className="text-danger">
                                              {titleCase(
                                                item.revenueSharingMethod.toLowerCase()
                                              )}
                                            </span>
                                          </li>

                                          <li>
                                            Next Payment Date :
                                            <span className="text-primary">
                                              {format(item.repaymentStartDate)}
                                            </span>
                                          </li>
                                          <li>
                                            Created :
                                            <span className="text-primary">
                                              {format(item.createdAt)}
                                            </span>
                                          </li>
                                        </ul>
                                        <div className="form-button-action">
                                          <button
                                            type="button"
                                            data-toggle="tooltip"
                                            title=""
                                            className="btn btn-primary bg-danger"
                                            data-original-title="view"
                                            onClick={() => {}}
                                          >
                                            <i className="fa fa-times"></i>{" "}
                                            Reject
                                          </button>
                                          &nbsp;
                                          <button
                                            type="button"
                                            data-toggle="tooltip"
                                            title=""
                                            className="btn btn-primary"
                                            data-original-title="view"
                                            onClick={() => {}}
                                          >
                                            <i className="fa fa-check"></i>{" "}
                                            Approve
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      )
                    ) : (
                      <div className="row card">
                        <div className="text-center message-box">
                          <p>No payment made</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="row card">
            <div className="text-center message-box">
              <p>Investment not found</p>
            </div>
          </div>
        )}
      </div>
    </OtherPageBody>
  );
};
export default InvestmentDetail;
