import React, { useState, useEffect } from "react";
import OtherPageBody from "../users/OtherPageBody";
import InputField from "./components/inputs/InputField";
import { ToastContainer, toast } from "react-toastify";
import OptionField from "./components/select/OptionField";
import FileInput from "./components/inputs/FileInput";
import { DocumentType } from "../../enums/DocumentType";
import { fileService } from "../../service/file.service";
import { useNavigate } from "react-router-dom";
import TinyLoader from "./components/loaders/TinyLoader";
import { investmentService } from "../../service/investmen.service";
import { titleCase } from "title-case";
import Loader from "./components/loaders/Loader";
import { util } from "../../utils/constants/constants";
var FormData = require("form-data");

const RequestInvestment = () => {
  const inputData = {
    ownerName: "",
    ownerPhoneNumber: "",
    ownerEmailAddress: "",
    idCardType: "",
    idCard: "",
    accountNumber: "",
    accountName: "",
    bankName: "",
    guarantorName: "",
    guarantorPhoneNumber: "",
    guarantorIdCardType: "",
    guarantorIdCard: "",
    amount: 0,
    revenueAmount: 0,
    revenueSharingMethod: "",
    revenueReturnPercentage: "",
    firstRefereeName: "",
    firstRefereePhoneNumber: "",
    secondRefereeName: "",
    secondRefereePhoneNumber: "",
  };
  const [isProcessing, setIsProcessing] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [input, setInput] = useState(inputData);
  const [step, setStep] = useState({
    step1: true,
    step2: false,
    step3: false,
    step4: false,
  });
  const [guarantorIdCard, setGuarantorIdCard] = useState();
  const [businessPlan, setBusinessPlan] = useState();
  const [idCard, setIdCard] = useState();
  const navigate = useNavigate();

  const onSelectFile = (e, type) => {
    var fileData = [];
    const files = e.target.files;

    if (files.length > 0) {
      fileData.push(files[0]);

      if (type === DocumentType.IdCard) setIdCard(fileData);
      if (type === DocumentType.GuarantorIdCard) setGuarantorIdCard(fileData);
      if (type === DocumentType.BusinessPlan) setBusinessPlan(fileData);
    }
  };

  const validBasicInformation = () => {
    if (step.step1) {
      if (input.ownerName === "" || input.ownerName.length < 3) {
        toast.error("Valid Personal Name Required");
        return false;
      } else if (
        input.ownerPhoneNumber == "" ||
        input.ownerPhoneNumber.length != 11
      ) {
        toast.error("Valid Phone Number Required");
        return false;
      } else if (
        input.idCardType === "" ||
        input.idCardType === "Select Id Type..."
      ) {
        toast.error("Select Id Type");
        return false;
      } else if (idCard === "" || !idCard) {
        toast.error("Upload Valid Id Card");
        return false;
      } else if (businessPlan === "" || !businessPlan) {
        toast.error("Upload Business Plan Card");
        return false;
      } else {
        return true;
      }
    }
  };

  const validAdditionalInformation = () => {
    if (step.step2) {
      if (input.accountNumber === "" || input.accountNumber.length !== 10) {
        toast.error("Valid Account Number Required");
        return false;
      } else if (input.accountName === "" || input.accountName.length !== 11) {
        toast.error("Valid Account Name Required");
        return false;
      } else if (
        input.bankName === "" ||
        input.bankName === "Select Bank Name..."
      ) {
        toast.error("Select Bank Name");
        return false;
      } else if (input.amount === "" || Number(input.amount) <= 0) {
        toast.error("Enter Amount Needed");
        return false;
      } else if (
        input.revenueAmount === "" ||
        Number(input.revenueAmount) <= 0
      ) {
        toast.error("Enter Expected Revenue Amount");
        return false;
      } else if (
        input.revenueSharingMethod === "" ||
        input.revenueSharingMethod === "Revenu Calculation Frequency..."
      ) {
        toast.error("Select Calculation Frequency");
        return false;
      } else if (
        input.revenueReturnPercentage === "" ||
        input.revenueReturnPercentage === "Select Percentage..."
      ) {
        toast.error("Select Revenue Percentage");
        return false;
      } else {
        return true;
      }
    }
  };

  const validGuarantorInformation = () => {
    if (step.step3) {
      if (input.guarantorName === "" || input.guarantorName.length < 3) {
        toast.error("Valid Guarantor Name Required");
        return false;
      } else if (
        input.guarantorPhoneNumber === "" ||
        input.guarantorPhoneNumber.length !== 11
      ) {
        toast.error("Valid Guarantor Number Required");
        return false;
      } else if (
        input.guarantorIdCardType === "" ||
        input.guarantorIdCardType === "Select Id Type..."
      ) {
        toast.error("Select Id Type");
        return false;
      } else if (guarantorIdCard === "" || !guarantorIdCard) {
        console.log(guarantorIdCard);
        toast.error("Upload Valid Guarantor Card");
        return false;
      } else {
        return true;
      }
    }
  };

  const validRefereesInformation = () => {
    if (step.step4) {
      if (input.firstRefereeName === "" || input.firstRefereeName.length < 3) {
        toast.error("Valid First Referee Name Required");
        return false;
      } else if (
        input.firstRefereePhoneNumber === "" ||
        input.firstRefereePhoneNumber.length !== 11
      ) {
        toast.error("Valid First Referee Number Required");
        return false;
      } else if (
        input.firstRefereeRelationship === "" ||
        input.firstRefereeRelationship === "Relationship..."
      ) {
        toast.error("Select Relationship with Referee");
        return false;
      } else if (
        input.secondRefereeName === "" ||
        input.secondRefereeName.length < 3
      ) {
        toast.error("Valid Second Referee Name Required");
        return false;
      } else if (
        input.secondRefereePhoneNumber === "" ||
        input.secondRefereePhoneNumber.length !== 11
      ) {
        toast.error("Valid Second Referee Number Required");
        return false;
      } else if (
        input.secondRefereeRelationship === "" ||
        input.secondRefereeRelationship === "Relationship..."
      ) {
        toast.error("Select Relationship with Referee");
        return false;
      } else {
        return true;
      }
    }
  };
  const requestInvestment = async () => {
    console.log(idCard);
    const filesData = new FormData();
    if (idCard) filesData.append("files[]", idCard[0]);
    if (guarantorIdCard) filesData.append("files[]", guarantorIdCard[0]);
    if (businessPlan) filesData.append("files[]", businessPlan[0]);
    console.log("filesData:", filesData);

    setIsProcessing(true);
    const fileResponse = await fileService.uploadFiles(filesData);
    if (fileResponse.data.success) {
      const fileURLs = fileResponse.data.data;

      input.idCard = fileURLs[0].fileName;
      input.guarantorIdCard = fileURLs[1].fileName;
      input.businessPlan = fileURLs[2].fileName;

      investmentService
        .createInvestment(input)
        .then((response) => {
          setIsProcessing(false);
          if (response.data.success) {
            const data = response.data.data;
            console.log("data:", data);
            toast.success("Request submited successfully");
            setTimeout(() => {
              navigate("/investment");
            }, 300);
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((e) => {
          setIsProcessing(false);
        })
        .finally(() => {
          setIsProcessing(false);
        });

      console.log("input:", input);
    } else {
      toast.error(fileResponse.data.message);
    }
  };
  useEffect(() => {}, []);

  return (
    <>
      <ToastContainer />
      <OtherPageBody>
        <div className="page-inner">
          <div className="row">
            <div className="col-xl-12">
              <div className="header-card">
                <div className="card-header">
                  <h4 className="text-left">
                    <i className="fa fa-pencil"></i> Request Funds
                  </h4>
                  <small>
                    By providing your information below, we will analyze your
                    request and credit you with the request funds.
                  </small>
                </div>
              </div>

              <div className="col-xl-8">
                <div className="panel">
                  <div
                    className={
                      step.step1
                        ? "card-header btn-primary collapse-form-header"
                        : "card-header btn-outline-secondary collapse-form-header"
                    }
                    onClick={() => {
                      setStep({ step1: !step.step1 });
                    }}
                  >
                    <h5 className="mb-0">Basic Information</h5>
                  </div>
                  <div
                    className={step.step1 ? "collapse show" : "collapse"}
                    aria-labelledby="headingOne"
                    data-parent="#accordion"
                  >
                    <div className="card-body collapse-form-body">
                      <div className="row">
                        <div className="col-md-6">
                          <InputField
                            type="text"
                            value={input.ownerName}
                            placeholder="Name"
                            label="Your Personal Name"
                            name="ownerName"
                            onChange={(e) =>
                              setInput({
                                ...input,
                                ownerName: e.target.value,
                              })
                            }
                            required={true}
                          />
                        </div>
                        <div className="col-md-6">
                          <InputField
                            type="text"
                            value={input.ownerEmailAddress}
                            placeholder="Email Address"
                            label="Your Email Address"
                            name="ownerEmailAddress"
                            onChange={(e) =>
                              setInput({
                                ...input,
                                ownerEmailAddress: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <InputField
                            type="text"
                            value={input.ownerPhoneNumber}
                            placeholder="Phone Number"
                            label="Your Phone Number"
                            name="ownerPhoneNumber"
                            onChange={(e) =>
                              setInput({
                                ...input,
                                ownerPhoneNumber: e.target.value,
                              })
                            }
                            required={true}
                          />
                        </div>
                        <div className="col-md-6">
                          <OptionField
                            children={[
                              "Select Id Type...",
                              "National Id Card",
                              "International Passport",
                              "Driving Licence",
                            ]}
                            value={input.idCardType}
                            placeholder="id Card Type"
                            label="Your Id Card Type"
                            name="idCardType"
                            onChange={(e) =>
                              setInput({
                                ...input,
                                idCardType: e.target.value,
                              })
                            }
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <FileInput
                            label="Upload Id Card"
                            name="idCard"
                            accept="image/*"
                            onChange={(e) => {
                              onSelectFile(e, DocumentType.IdCard);
                            }}
                            required={true}
                          />
                        </div>
                        <div className="col-md-6">
                          <FileInput
                            label="Business Plan (Pdf, Ppt)"
                            name="businessPlan"
                            accept="application/pdf, application/ppt"
                            onChange={(e) => {
                              onSelectFile(e, DocumentType.BusinessPlan);
                            }}
                            required={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="panel">
                  <div
                    className={
                      step.step2
                        ? "card-header btn-primary collapse-form-header"
                        : "card-header btn-outline-secondary collapse-form-header"
                    }
                    onClick={() => {
                      if (input.type !== "") setStep({ step2: !step.step2 });
                    }}
                  >
                    <h5 className="mb-0">Additional Information</h5>
                  </div>
                  <div
                    className={step.step2 ? "collapse show" : "collapse"}
                    aria-labelledby="headingTwo"
                    data-parent="#accordion"
                  >
                    <div className="card-body collapse-form-body">
                      <div className="row">
                        <div className="row">
                          <div className="col-md-6">
                            <InputField
                              type="number"
                              label="Account Number"
                              placeholder="Account Number"
                              value={input.accountNumber}
                              name="accountNumber"
                              required={true}
                              onChange={(e) =>
                                setInput({
                                  ...input,
                                  accountNumber: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="col-md-6">
                            <InputField
                              type="text"
                              label="Account Name"
                              placeholder="Account Name"
                              name="accountName"
                              value={input.accountName}
                              required={true}
                              onChange={(e) =>
                                setInput({
                                  ...input,
                                  accountName: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <OptionField
                              children={[util.getBanks()]}
                              label="Bank Name"
                              value={input.bankName}
                              name="bankName"
                              required={true}
                              onChange={(e) =>
                                setInput({
                                  ...input,
                                  bankName: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="col-md-6">
                            <InputField
                              type="number"
                              label="Amount"
                              value={input.amount}
                              placeholder="Amount Needed"
                              name="amount"
                              required={true}
                              onChange={(e) =>
                                setInput({
                                  ...input,
                                  amount: Number(e.target.value),
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <OptionField
                              children={[
                                "Revenu Calculation Frequency...",
                                "DAILY",
                                "WEEKLY",
                                "MONTHLY",
                                "QUATERLY",
                                "HALFLY",
                                "YEARLY",
                              ]}
                              value={input.revenueSharingMethod}
                              label="Revenue Calculation Frequency"
                              name="revenueSharingMethod"
                              required={true}
                              onChange={(e) =>
                                setInput({
                                  ...input,
                                  revenueSharingMethod: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="col-md-6">
                            <InputField
                              type="number"
                              value={input.revenueAmount}
                              label={`Expected Total Revenue ${
                                input.revenueSharingMethod &&
                                input.revenueSharingMethod !==
                                  "Revenu Calculation Frequency..."
                                  ? titleCase(
                                      input.revenueSharingMethod.toLowerCase()
                                    )
                                  : ""
                              } `}
                              placeholder="Expected Revenue Amount"
                              name="revenueAmount"
                              required={true}
                              onChange={(e) =>
                                setInput({
                                  ...input,
                                  revenueAmount: Number(e.target.value),
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <OptionField
                              children={[
                                "Select Percentage...",
                                "5%",
                                "10%",
                                "15%",
                                "20%",
                                "25%",
                                "30%",
                                "35%",
                                "40%",
                                "45%",
                                "50%",
                                "55%",
                                "60%",
                                "65%",
                                "70%",
                              ]}
                              value={input.revenueReturnPercentage}
                              label={`Revenue Share for Investor ${
                                input.revenueSharingMethod &&
                                input.revenueSharingMethod !==
                                  "Revenu Calculation Frequency..."
                                  ? titleCase(
                                      input.revenueSharingMethod.toLowerCase()
                                    )
                                  : ""
                              } `}
                              placeholder="Select Percentage"
                              name="revenueReturnPercentage"
                              required={true}
                              onChange={(e) =>
                                setInput({
                                  ...input,
                                  revenueReturnPercentage: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="panel">
                  <div
                    className={
                      step.step3
                        ? "card-header btn-primary collapse-form-header"
                        : "card-header btn-outline-secondary collapse-form-header"
                    }
                    onClick={() => {
                      if (input.type !== "") setStep({ step3: !step.step3 });
                    }}
                  >
                    <h5 className="mb-0">Guarantor Information</h5>
                  </div>
                  <div
                    className={step.step3 ? "collapse show" : "collapse"}
                    aria-labelledby="headingTwo"
                    data-parent="#accordion"
                  >
                    <div className="card-body collapse-form-body">
                      <div className="row">
                        <div className="row">
                          <div className="col-md-6">
                            <InputField
                              type="text"
                              label=" Guarantor Name"
                              value={input.guarantorName}
                              placeholder="Your Guarantor Name"
                              name="guarantorName"
                              required={true}
                              onChange={(e) =>
                                setInput({
                                  ...input,
                                  guarantorName: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="col-md-6">
                            <InputField
                              type="tel"
                              label="Guarantor Phone Number"
                              value={input.guarantorPhoneNumber}
                              placeholder="Your Guarantor Phone Number"
                              name="guarantorPhoneNumber"
                              required={true}
                              onChange={(e) =>
                                setInput({
                                  ...input,
                                  guarantorPhoneNumber: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <OptionField
                              children={[
                                "Select Id Type...",
                                "National Id Card",
                                "International Passport",
                                "Driving Licence",
                              ]}
                              value={input.guarantorIdCardType}
                              placeholder="Guarantor Id Card Type"
                              label="Guarantor Id Card Type"
                              name="guarantorIdCardType"
                              onChange={(e) =>
                                setInput({
                                  ...input,
                                  guarantorIdCardType: e.target.value,
                                })
                              }
                              required={true}
                            />
                          </div>
                          <div className="col-md-6">
                            <FileInput
                              label="Guarantor Id Card"
                              text="Guarantor Id Card"
                              name="guarantorIdCard"
                              accept="image/*"
                              onChange={(e) => {
                                onSelectFile(e, DocumentType.GuarantorIdCard);
                              }}
                              required={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="panel">
                  <div
                    className={
                      step.step4
                        ? "card-header btn-primary collapse-form-header"
                        : "card-header btn-outline-secondary collapse-form-header"
                    }
                    onClick={() => {
                      if (input.type !== "") setStep({ step4: !step.step4 });
                    }}
                  >
                    <h5 className="mb-0">Referees Information</h5>
                  </div>
                  <div
                    className={step.step4 ? "collapse show" : "collapse"}
                    aria-labelledby="headingTwo"
                    data-parent="#accordion"
                  >
                    <div className="card-body collapse-form-body">
                      <div className="row">
                        <div className="col-md-4">
                          <InputField
                            type="text"
                            label="First Referee Name"
                            value={input.firstRefereeName}
                            placeholder="Your First Referee Name"
                            name="firstRefereeName"
                            required={true}
                            onChange={(e) =>
                              setInput({
                                ...input,
                                firstRefereeName: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="col-md-4">
                          <InputField
                            type="tel"
                            label="Referee Phone Number"
                            value={input.firstRefereePhoneNumber}
                            placeholder="Referee Phone Number"
                            name="firstRefereePhoneNumber"
                            required={true}
                            onChange={(e) =>
                              setInput({
                                ...input,
                                firstRefereePhoneNumber: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="col-md-4">
                          <OptionField
                            children={[
                              "Relationship...",
                              "Employer",
                              "Partner",
                              "Customer",
                              "Colegue",
                            ]}
                            label="Relationship with Referee"
                            value={input.firstRefereeRelationship}
                            placeholder="Relationship"
                            name="firstRefereeRelationship"
                            required={true}
                            onChange={(e) =>
                              setInput({
                                ...input,
                                firstRefereeRelationship: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-md-4">
                          <InputField
                            type="text"
                            label="Second Referee Name"
                            value={input.secondRefereeName}
                            placeholder="Your Second Referee Name"
                            name="secondRefereeName"
                            required={true}
                            onChange={(e) =>
                              setInput({
                                ...input,
                                secondRefereeName: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="col-md-4">
                          <InputField
                            type="tel"
                            label="Referee Phone Number"
                            value={input.secondRefereePhoneNumber}
                            placeholder="Referee Phone Number"
                            name="secondRefereePhoneNumber"
                            required={true}
                            onChange={(e) =>
                              setInput({
                                ...input,
                                secondRefereePhoneNumber: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="col-md-4">
                          <OptionField
                            children={[
                              "Relationship...",
                              "Employer",
                              "Partner",
                              "Customer",
                              "Colegue",
                            ]}
                            label="Relationship with Referee"
                            value={input.secondRefereeRelationship}
                            placeholder="Relationship"
                            name="secondRefereeRelationship"
                            required={true}
                            onChange={(e) =>
                              setInput({
                                ...input,
                                secondRefereeRelationship: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="panel">
                  <div
                    className={
                      step.step5
                        ? "card-header btn-primary collapse-form-header"
                        : "card-header btn-outline-secondary collapse-form-header"
                    }
                    onClick={() => {
                      if (input.type !== "") setStep({ step5: !step.step5 });
                    }}
                  >
                    <h5 className="mb-0">Terms and Condition</h5>
                  </div>
                  <div
                    className={step.step5 ? "collapse show" : "collapse"}
                    aria-labelledby="headingTwo"
                    data-parent="#accordion"
                  >
                    <div className="card-body collapse-form-body">
                      <h2>Terms and Condition</h2>
                      <p>skfjsh dksjkdjs dkjskdjks</p>

                      <div className="agree">
                        <input
                          type="checkbox"
                          onChange={() => {
                            setTermsAccepted(!termsAccepted);
                          }}
                        />
                        <label htmlFor="agree">
                          &nbsp; I agree to <b>terms and conditions</b>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <button
                    className="btn btn-primary p-3 btn-round ml-auto m-3  long-button"
                    width="100"
                    onClick={
                      isProcessing
                        ? null
                        : () => {
                            if (validBasicInformation() && step.step1) {
                              setStep({ step2: true });
                            } else if (
                              validAdditionalInformation() &&
                              step.step2
                            ) {
                              setStep({ step3: true });
                            } else if (
                              validGuarantorInformation() &&
                              step.step3
                            ) {
                              setStep({ step4: true });
                            } else if (
                              validRefereesInformation() &&
                              step.step4
                            ) {
                              setStep({ step5: true });
                            } else {
                              if (step.step5) {
                                if (termsAccepted) {
                                  requestInvestment();
                                } else {
                                  toast.error(
                                    "Accept Terms and Condition to Proceed"
                                  );
                                }
                              }
                            }
                          }
                    }
                  >
                    {isProcessing ? (
                      <TinyLoader />
                    ) : step.step5 ? (
                      "Submit Request"
                    ) : (
                      "Next"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </OtherPageBody>
    </>
  );
};

export default RequestInvestment;
