import { util } from "../utils/constants/constants";
import { http } from "../utils/constants/http";
import { storage } from "../utils/constants/storage";

let repaymentService = {
  createRepayment: async (body) => {
    return await http.post(`${util.endPoint}/investment/repayment`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  updateRepayment: async (id, body) => {
    return await http.put(`${util.endPoint}/investment/repayment/${id}`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  deleteRepayment: async (id) => {
    return await http.delete(`${util.endPoint}/investment/repayment/${id}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  getAllRepaymentByInvestmentId: async (currentPage, investmentId) => {
    let url = `/investment/repayment/list/${investmentId}?page=${currentPage}`;
    return await http.get(`${util.endPoint}${url}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  searchRepayments: async (currentPage, searchText) => {
    let url = `/repayment/search?page=${currentPage}&q=${searchText}`;

    return await http.get(`${util.endPoint}/investment/${url}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  getRepaymentAnalytics: async (filter) => {
    let url = `${util.endPoint}/investment/report/repayment/analytics`;
    if (filter && filter.from && filter.to) {
      url += `?from=${filter.from}&to=${filter.to}`;
    } else {
      const currentCycle = util.decodeCycle(storage.get("cycle"));
      url += `?from=${currentCycle.from}&to=${currentCycle.to}`;
    }
    if (filter && filter.actionBy) url += `&actionBy=${filter.actionBy}`;
    return await http.get(url, {
      headers: util.getAuthorizedHeaders(),
    });
  },
};

export { repaymentService };
