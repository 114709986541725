import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { titleCase } from "title-case";
import { UserContext } from "../../../Access";
import Avatar from "../../../assets/img/avatar.png";
import { UserType } from "../../../enums/UserType";
import shape1 from "./../../../assets/img/shape/vector-shape11.png";
import shape2 from "./../../../assets/img/shape/shape2.png";
import shape3 from "./../../../assets/img/shape/shape3.png";
import { BusinessTarget } from "../../../enums/BusinessTarget";
import { storage } from "../../../utils/constants/storage";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { UserRole } from "../../../enums/UserRole";
import { toast } from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";

const SideBar = () => {
  const cycle = storage.get("cycle");
  const userSession = useContext(UserContext);
  const [showAccountNumber, setShowAccountNumber] = useState(false);
  const [openThemeModal, setOpenThemeModal] = useState(false);
  const [openPrinterModal, setOpenPrinterModal] = useState(false);
  const [openCycleModal, setOpenCycleModal] = useState(false);
  const [printerType] = useState(storage.get("printer"));
  const user = userSession.user;

  const [dropdown, setDropdown] = useState({
    profile: false,
    users: false,
    branch: false,
    top: false,
    inventory: false,
    investment: false,
    setup: false,
    services: false,
  });

  const closeAll = () => {
    setDropdown({
      profile: false,
      users: false,
      top: false,
      branch: false,
      inventory: false,
      setup: false,
      investment: false,
      services: false,
    });
  };

  const changeTheme = (theme) => {
    storage.save("theme", theme);
    setOpenThemeModal(false);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const changePrinter = (printerSize) => {
    storage.save("printer", printerSize);
    setOpenPrinterModal(false);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const changeCycle = (cycle) => {
    storage.save("cycle", cycle);
    window.location.reload();
  };

  const logout = () => {
    storage.remove("userToken");
    storage.remove("user");
    toast.warn("Logging out...");
    setTimeout(() => {
      window.location = "/user/login";
    }, 500);
  };

  return (
    <div className="sidebar sidebar-style-2">
      <Dialog open={openThemeModal}>
        <DialogTitle>Select Theme</DialogTitle>
        <DialogContent>
          <div className="theme-option">
            <li onClick={() => changeTheme("grayscale(1)")}>Dark</li>
            <li onClick={() => changeTheme("hue-rotate(45deg)")}>Hue</li>
            <li onClick={() => changeTheme("invert(1)")}>Invert</li>
            <li onClick={() => changeTheme("sepia(1)")}>Sepia</li>
            <li onClick={() => changeTheme("saturate(0.5)")}>Saturate</li>
            <li onClick={() => changeTheme("unset")}>Default</li>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => {
              setOpenThemeModal(false);
            }}
            className="btn btn-danger btn-border btn-round mr-2"
          >
            Okay
          </button>
        </DialogActions>
      </Dialog>

      <Dialog open={openPrinterModal}>
        <DialogTitle>Select Printer Size</DialogTitle>
        <DialogContent>
          <div className="theme-option">
            <li
              className={printerType === "X-LARGE" ? "active" : ""}
              onClick={() => changePrinter("X-LARGE")}
            >
              X-Large
            </li>
            <li
              className={printerType === "LARGE" ? "active" : ""}
              onClick={() => changePrinter("LARGE")}
            >
              Large
            </li>
            <li
              className={printerType === "MEDIUM" ? "active" : ""}
              onClick={() => changePrinter("MEDIUM")}
            >
              Medium
            </li>
            <li
              className={printerType === "SMALL" ? "active" : ""}
              onClick={() => changePrinter("SMALL")}
            >
              Small
            </li>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => {
              setOpenPrinterModal(false);
            }}
            className="btn btn-danger btn-border btn-round mr-2"
          >
            Okay
          </button>
        </DialogActions>
      </Dialog>

      <Dialog open={openCycleModal}>
        <DialogTitle>Select Cycle</DialogTitle>
        <DialogContent>
          <div className="theme-option">
            <li
              className={cycle === "DAILY" ? "active" : ""}
              onClick={() => changeCycle("DAILY")}
            >
              Daily
            </li>
            <li
              className={cycle === "MONTHLY" ? "active" : ""}
              onClick={() => changeCycle("MONTHLY")}
            >
              Monthly
            </li>
            <li
              className={cycle === "YEARLY" ? "active" : ""}
              onClick={() => changeCycle("YEARLY")}
            >
              Yearly
            </li>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => {
              setOpenCycleModal(false);
            }}
            className="btn btn-danger btn-border btn-round mr-2"
          >
            Okay
          </button>
        </DialogActions>
      </Dialog>

      <div className="sidebar-wrapper scrollbar scrollbar-inner">
        <div className="sidebar-content">
          <div className="user">
            <div
              className={
                "avatar-sm float-left mr-2" + (dropdown.top ? " show" : "")
              }
            >
              <img
                src={user.dp ? user.dp : Avatar}
                onError={(e) => (e.target.src = Avatar)}
                alt="..."
                className="avatar-img rounded-circle"
              />
            </div>
            <div className="info">
              <Link
                to="#"
                data-toggle="collapse"
                aria-expanded={dropdown.profile ? "true" : "false"}
              >
                <span>
                  {titleCase(user.name ? user.name : "Unknown User")}

                  {user.role === UserRole.User &&
                  user.accountType === UserType.Business ? (
                    <small className="text-lightgrey">
                      <strong>{user.business}</strong>
                    </small>
                  ) : (
                    <span className="user-code">
                      {user.code
                        ? showAccountNumber
                          ? user.code
                          : user.code.toString().substring(0, 2) + "****"
                        : "Online"}
                      <i
                        className="fa fa-eye"
                        onClick={() => {
                          setShowAccountNumber(!showAccountNumber);
                        }}
                      ></i>
                    </span>
                  )}
                </span>
              </Link>
              <div className="clearfix"></div>

              <div
                className={"collapse in" + (dropdown.top ? " show" : "")}
                id="collapseExample"
              ></div>
            </div>
          </div>
          <ul className="nav nav-primary">
            <li className="nav-item active">
              <Link to="/dashboard">
                <i className="fas fa-home"></i>
                <p>Dashboard</p>
              </Link>
            </li>
            <li className="nav-section">
              <span className="sidebar-mini-icon">
                <i className="fa fa-ellipsis-h"></i>
              </span>
            </li>

            {user.accountType !== UserType.Individual ? (
              <>
                {user.role === UserRole.User &&
                user.accountType === UserType.Business ? (
                  <></>
                ) : (
                  <>
                    <li
                      className={"nav-item" + (dropdown.users ? " show" : "")}
                    >
                      <Link
                        to="/user/all"
                        data-toggle="collapse"
                        className={dropdown.users ? "btn-highlight" : ""}
                      >
                        <i className="fas fa-users"></i>
                        <p>Manage Users</p>
                      </Link>
                    </li>
                    <li
                      className={"nav-item" + (dropdown.branch ? " show" : "")}
                    >
                      <Link
                        to="/branch/all"
                        data-toggle="collapse"
                        className={dropdown.branch ? "btn-highlight" : ""}
                      >
                        <i className="fas fa-industry"></i>
                        <p>Manage Branches</p>
                      </Link>
                    </li>
                  </>
                )}

                {user.businessTarget === BusinessTarget.Product ||
                user.businessTarget === BusinessTarget.General ||
                user.accountType === UserType.Admin ? (
                  <>
                    <li
                      className={
                        "nav-item" + (dropdown.inventory ? " show" : "")
                      }
                      onClick={() => {
                        closeAll();
                        setDropdown({
                          ...dropdown,
                          inventory: !dropdown.inventory,
                        });
                      }}
                    >
                      <a
                        data-toggle="collapse"
                        href="#base"
                        className={dropdown.inventory ? "btn-highlight" : ""}
                      >
                        <i className="fas fa-store"></i>
                        <p>Manage Inventory</p>
                        <span
                          aria-expanded={dropdown.inventory}
                          className="caret"
                        ></span>
                      </a>
                      <div
                        className={
                          "collapse" + (dropdown.inventory ? " show" : "")
                        }
                        id="base"
                      >
                        <ul className="nav nav-collapse">
                          <Link to="/product/types">
                            <li>
                              <span className="sub-item">Product Types</span>
                            </li>
                          </Link>
                          <Link to="/inventory/products">
                            <li>
                              <span className="sub-item">Products</span>
                            </li>
                          </Link>
                          <Link to="/inventory/sales">
                            <li>
                              <span className="sub-item">Sales</span>
                            </li>
                          </Link>

                          <Link to="/inventory/analytics">
                            <li>
                              <span className="sub-item">Report</span>
                            </li>
                          </Link>
                        </ul>
                      </div>
                    </li>
                  </>
                ) : (
                  <></>
                )}

                {user.businessTarget === BusinessTarget.Service ||
                user.businessTarget === BusinessTarget.General ||
                user.accountType === UserType.Admin ? (
                  <>
                    <li
                      className={
                        "nav-item" + (dropdown.services ? " show" : "")
                      }
                      onClick={() => {
                        closeAll();
                        setDropdown({
                          ...dropdown,
                          services: !dropdown.services,
                        });
                      }}
                    >
                      <a
                        data-toggle="collapse"
                        href="#base"
                        className={dropdown.services ? "btn-highlight" : ""}
                      >
                        <i className="fas fa-server"></i>
                        <p>Manage Services</p>
                        <span
                          aria-expanded={dropdown.services}
                          className="caret"
                        ></span>
                      </a>
                      <div
                        className={
                          "collapse" + (dropdown.services ? " show" : "")
                        }
                        id="base"
                      >
                        <ul className="nav nav-collapse">
                          <Link to="/service/types">
                            <li>
                              <span className="sub-item">Service Types</span>
                            </li>
                          </Link>
                          <Link to="/inventory/services">
                            <li>
                              <span className="sub-item">Services</span>
                            </li>
                          </Link>

                          <Link to="/inventory/transactions">
                            <li>
                              <span className="sub-item">Transactions</span>
                            </li>
                          </Link>
                        </ul>
                      </div>
                    </li>
                  </>
                ) : (
                  <></>
                )}

                <li
                  className={"nav-item" + (dropdown.investment ? " show" : "")}
                  onClick={() => {
                    closeAll();
                    setDropdown({
                      ...dropdown,
                      investment: !dropdown.investment,
                    });
                  }}
                >
                  <a
                    data-toggle="collapse"
                    href="#base"
                    className={dropdown.investment ? "btn-highlight" : ""}
                  >
                    <i className="fas fa-wallet"></i>
                    <p>Manage Investment</p>
                    <span
                      aria-expanded={dropdown.investment}
                      className="caret"
                    ></span>
                  </a>
                  <div
                    className={
                      "collapse" + (dropdown.investment ? " show" : "")
                    }
                    id="base"
                  >
                    <ul className="nav nav-collapse">
                      <Link to="/investment">
                        <li>
                          <span className="sub-item">Investments</span>
                        </li>
                      </Link>
                      <Link to="#">
                        <li>
                          <span className="sub-item">Report</span>
                        </li>
                      </Link>
                    </ul>
                  </div>
                </li>
              </>
            ) : (
              <></>
            )}

            {user.accountType !== UserType.Individual ? (
              <>
                <li className="nav-item">
                  <Link to="/expenses" data-toggle="collapse">
                    <i className="fas fa-money-bill-wave-alt"></i>
                    <p>Manage Expenses</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/deposit" data-toggle="collapse">
                    <i className="fas fa-money-bill"></i>
                    <p>Manage Deposit</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/debt" data-toggle="collapse">
                    <i className="fas fa-money-check"></i>
                    <p>Manage Debts</p>
                  </Link>
                </li>
              </>
            ) : (
              <></>
            )}

            <li
              className={"nav-item" + (dropdown.profile ? " show" : "")}
              onClick={() => {
                closeAll();
                setDropdown({
                  ...dropdown,
                  profile: !dropdown.profile,
                });
              }}
            >
              <a
                data-toggle="collapse"
                href="#base"
                className={dropdown.profile ? "btn-highlight" : ""}
              >
                <i className="fas fa-user"></i>
                <p>Manage Account</p>
                <span aria-expanded={dropdown.profile} className="caret"></span>
              </a>
              <div
                className={"collapse" + (dropdown.profile ? " show" : "")}
                id="base"
              >
                <ul className="nav nav-collapse">
                  <Link to="/user/profile">
                    <li>
                      <span className="sub-item">View Profile</span>
                    </li>
                  </Link>
                  <Link to="/user/reset-password">
                    <li>
                      <span className="sub-item">Reset Password</span>
                    </li>
                  </Link>

                  <Link to="#">
                    <li>
                      <CopyToClipboard
                        text={
                          "https://dashboard.fayib.com/user/register/business?referee=?" +
                          user.code
                        }
                        onCopy={() => {
                          toast.success("Referral Link Copied...");
                        }}
                      >
                        <div className="sub-item">Copy Referral Link</div>
                      </CopyToClipboard>
                    </li>
                  </Link>

                  <Link to="#" onClick={logout}>
                    <li>
                      <span className="sub-item">Logout</span>
                    </li>
                  </Link>
                </ul>
              </div>
            </li>
            {user.accountType !== UserType.Individual ? (
              <li
                className={"nav-item" + (dropdown.setup ? " show" : "")}
                onClick={() => {
                  closeAll();
                  setDropdown({
                    ...dropdown,
                    setup: !dropdown.setup,
                  });
                }}
              >
                <a
                  data-toggle="collapse"
                  href="#base"
                  className={dropdown.setup ? "btn-highlight" : ""}
                >
                  <i className="fas fa-cogs"></i>
                  <p>Settings</p>
                  <span aria-expanded={dropdown.setup} className="caret"></span>
                </a>
                <div
                  className={"collapse" + (dropdown.setup ? " show" : "")}
                  id="base"
                >
                  <ul className="nav nav-collapse">
                    {user.accountType === UserType.Admin ? (
                      <>
                        <Link to="/business/types">
                          <li>
                            <span className="sub-item">Business types</span>
                          </li>
                        </Link>
                      </>
                    ) : user.accountType === UserType.Business ? (
                      <></>
                    ) : (
                      <></>
                    )}

                    <Link
                      to="#"
                      onClick={() => {
                        setOpenThemeModal(true);
                      }}
                    >
                      <li>
                        <span className="sub-item">Change Theme</span>
                      </li>
                    </Link>

                    <Link
                      to="#"
                      onClick={() => {
                        setOpenCycleModal(true);
                      }}
                    >
                      <li>
                        <span className="sub-item">Change Cycle</span>
                      </li>
                    </Link>

                    <Link
                      to="#"
                      onClick={() => {
                        setOpenPrinterModal(true);
                      }}
                    >
                      <li>
                        <span className="sub-item">Change Printer</span>
                      </li>
                    </Link>
                  </ul>
                </div>
              </li>
            ) : (
              <></>
            )}
          </ul>
          <div className="shape-img1">
            <img src={shape1} alt="images" />
          </div>
          <div className="shape-img2">
            <img src={shape2} alt="images" />
          </div>
          <div className="shape-img3">
            <img src={shape3} alt="images" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
