import React, { useState } from "react";
import Button from "./components/button/Button";
import InputField from "./components/inputs/InputField";
import { auth } from "../../service/auth.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { messages } from "../../utils/constants/messages";
import { storage } from "../../utils/constants/storage";
import { Link, useNavigate } from "react-router-dom";
import shape1 from "./../../assets/img/shape/vector-shape11.png";
import shape2 from "./../../assets/img/shape/shape2.png";
import shape3 from "./../../assets/img/shape/shape3.png";
import SignNavBar from "./components/SignNavBar";
import TinyLoader from "./components/loaders/TinyLoader";

const ResetPassword = () => {
  const storedUsername = storage.get("username");
  const [validated, setValidated] = useState(false);
  const [input, setInput] = useState({
    username:
      typeof storedUsername !== "undefined" && storedUsername !== "undefined"
        ? storedUsername
        : "",
    password: "",
    confirmPassword: "",
    otp: "",
  });
  let [isProcessing, setIsProcessing] = useState(false);
  let [isResendingOTP, setIsResendingOTP] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validateUser = async () => {
    if (input.username == null) toast.error(messages.usernameMessage);

    if (input.username !== null) {
      setIsProcessing(true);
      auth
        .validateAccount({
          username: input.username,
        })
        .then((result) => {
          setIsProcessing(false);
          if (result.data.success) {
            setValidated(true);
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(messages.requestFailed);
        });
    }
  };

  const resendOTP = async () => {
    if (input.username == null) toast.error(messages.usernameMessage);

    if (input.username !== null) {
      setIsResendingOTP(true);
      auth
        .validateAccount({
          username: input.username,
        })
        .then((result) => {
          setIsResendingOTP(false);
          if (result.data.success) {
            setValidated(true);
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(messages.requestFailed);
        });
    }
  };

  const changePassword = async () => {
    if (input.otp == null || input.otp.length < 6)
      toast.error(messages.otpLength);
    else if (input.password == null || input.password.length < 3)
      toast.error(messages.passwordLengthMessage);
    else if (input.username == null)
      toast.error(messages.passwordLengthMessage);

    if (
      input.otp &&
      input.otp.length === 6 &&
      input.password &&
      input.password.length >= 3 &&
      input.username != null &&
      input.username.length === 11
    ) {
      if (input.password !== input.confirmPassword) {
        toast.error("Password did not match");
      } else {
        const request = {
          username: input.username,
          password: input.password,
          otp: input.otp,
        };
        setIsProcessing(true);
        auth
          .resetPassword(request)
          .then((result) => {
            setIsProcessing(false);
            if (result.data.success) {
              toast.success(result.data.message);
              setTimeout(() => {
                navigate("/user/login");
              }, 500);
            } else {
              toast.error(result.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error(messages.requestFailed);
          });
      }
    }
  };

  return (
    <div className="wrapper sign-body">
      <SignNavBar />
      <ToastContainer />
      <div className="page-title-area">
        <div className="container">
          <div className="page-title-content">
            <h2>Reset Password</h2>
            <ul>
              <li>Remember your password?</li>
              &nbsp;
              <li>
                <i className="fa fa-pencil-square"></i>
                <Link to="/user/login">Signin</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="shape-img1">
          <img src={shape1} alt="images" />
        </div>
        <div className="shape-img2">
          <img src={shape2} alt="images" />
        </div>
        <div className="shape-img3">
          <img src={shape3} alt="images" />
        </div>
      </div>
      <div className="sign-container">
        <div className="rounded">
          <div className="card-body">
            {!validated ? (
              <div className="">
                <InputField
                  type="text"
                  value={input.username}
                  placeholder="Phone Number Email or ID Number"
                  label="Your Username"
                  name="username"
                  onChange={handleChange}
                  required
                />
              </div>
            ) : (
              <></>
            )}

            {validated ? (
              <div>
                <div className="">
                  <InputField
                    type="text"
                    value={input.otp}
                    maxLength="6"
                    placeholder="Enter OTP"
                    label="otp"
                    name="otp"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="">
                  <InputField
                    type="password"
                    value={input.password}
                    placeholder="New Password"
                    label="Password"
                    name="password"
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="">
                  <InputField
                    type="password"
                    value={input.confirmPassword}
                    placeholder="Confirm Password"
                    label="Password"
                    name="confirmPassword"
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
            ) : (
              <></>
            )}

            <div className="button">
              <Button
                isLoading={isProcessing}
                type="button"
                value={
                  isProcessing
                    ? messages.processingMessage
                    : validated
                    ? "Change Password"
                    : "Continue"
                }
                name="button"
                as
                onClick={() =>
                  !isProcessing
                    ? validated
                      ? changePassword()
                      : validateUser()
                    : null
                }
                contain={true}
              />
            </div>
            <div className="my-2">
              <div className="float-right text-sm">
                <Link to="/user/login">Remember Password ?</Link>
              </div>
              <div className="">
                {validated ? (
                  <button className="link-btn" onClick={() => resendOTP()}>
                    {isResendingOTP ? <TinyLoader /> : "Resend OTP"}
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
