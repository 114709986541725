import { util } from "../utils/constants/constants";
import { http } from "../utils/constants/http";
import { storage } from "../utils/constants/storage";

let disbursalService = {
  createDisbursal: async (body) => {
    return await http.post(`${util.endPoint}/investment/disbursal`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  updateDisbursal: async (id, body) => {
    return await http.put(`${util.endPoint}/investment/disbursal/${id}`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  deleteDisbursal: async (id) => {
    return await http.delete(`${util.endPoint}/investment/disbursal/${id}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  getDisbursalByInvestmentId: async (investmentId) => {
    let url = `/investment/disbursal/details/${investmentId}`;
    return await http.get(`${util.endPoint}${url}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  getDisbursalAnalytics: async (filter) => {
    let url = `${util.endPoint}/investment/report/repayment/analytics`;
    if (filter && filter.from && filter.to) {
      url += `?from=${filter.from}&to=${filter.to}`;
    } else {
      const currentCycle = util.decodeCycle(storage.get("cycle"));
      url += `?from=${currentCycle.from}&to=${currentCycle.to}`;
    }
    if (filter && filter.actionBy) url += `&actionBy=${filter.actionBy}`;
    return await http.get(url, {
      headers: util.getAuthorizedHeaders(),
    });
  },
};

export { disbursalService };
