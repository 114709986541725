import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { unitService } from "../../service/unit.service";
import { format } from "timeago.js";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputField from "./components/inputs/InputField";
import TextArea from "./components/inputs/TextArea";
import Loader from "./../users/components/loaders/Loader";
import { util } from "../../utils/constants/constants";
import { closePaymentModal, useFlutterwave } from "flutterwave-react-v3";
import { Link } from "react-router-dom";
import Chart from "react-apexcharts";
import { inventoryService } from "../../service/inventory.service";
import { UserType } from "../../enums/UserType";
import { userService } from "../../service/user.service";
import { storage } from "../../utils/constants/storage";
import { UserContext } from "../../Access";
import { BusinessTarget } from "../../enums/BusinessTarget";
import { UserRole } from "../../enums/UserRole";
import SearchField from "./components/inputs/SearchField";
import TinyLoader from "./components/loaders/TinyLoader";
import OptionField from "./components/select/OptionField";
import { titleCase } from "title-case";
import millify from "millify";
import logo from "./../../assets/img/logoWhite.png";

const DashBody = () => {
  const userSession = useContext(UserContext);
  const user = userSession.user;

  const [cycle, setCycle] = useState(storage.get("cycle"));
  const [searchText, setSearchText] = useState("");
  const [searchResult, setSearchResult] = useState(null);
  const [searchProgress, setSearchProgress] = useState(false);

  const [inventoryChartSeries, setInventoryChartSeries] = useState([
    {
      name: "Inventory Analytics",
      data: [0, 0, 0, 0, 0],
    },
  ]);
  const [inventoryChartOptions, setInventoryChartOptions] = useState({
    chart: {
      id: "inventory-analytics",
    },
    xaxis: {
      categories: ["Sales", "Sold", "Left", "Discount", "Revenue"],
    },
  });

  const [unitChartSeries, setUnitChartSeries] = useState([
    {
      name: "  Unit Analytics",
      data: [0, 0, 0],
    },
  ]);
  const [unitChartOptions, setUnitChartOptions] = useState({
    chart: {
      id: " unit-analytics",
    },
    xaxis: {
      categories: ["Total Balance", "Total Credit", "Total Debit"],
    },
  });

  const [unit, setUnit] = useState();
  const [unitLogs, setUnitLogs] = useState(null);
  const [unitAnalytics, setUnitAnalytics] = useState();
  const [inventoryAnalytic, setInventoryAnalytic] = useState();
  const [unitProgress, setUnitProgress] = useState();
  const [openTransferModal, setOpenTransferModal] = useState(false);
  const [openWithdrawModal, setOpenWithdrawModal] = useState(false);
  const [openFundingModal, setOpenFundingModal] = useState(false);
  const [transferProgress, setTransferProgress] = useState(false);
  const [withdrawProgress, setWithdrawProgress] = useState(false);
  const [fundingProgress, setFundingProgress] = useState(false);
  const [paymentRef, setPaymentRef] = useState(util.getRandomString());

  const [transferInput, setTransferInput] = useState({
    recipient: "",
    amount: "",
    narration: "",
  });
  const [withdrawInput, setWithdrawInput] = useState({
    accountType: "",
    accountName: "",
    accountNumber: "",
    amount: "",
  });
  const [fundingInput, setFundingInput] = useState({
    amount: "",
  });

  const config = {
    public_key: util.flutterwaveKey,
    tx_ref: paymentRef,
    amount: Number(fundingInput.amount),
    currency: "NGN",
    customer: {
      email: "support@fayib.com",
      phone_number: user.phoneNumber,
      name: user.name,
    },
    customizations: {
      title: "Fayib Unit Funding",
      description: "Payment for  unit",
      logo: "https://fayib.com/img/icon.png",
    },
  };
  const handleFlutterPayment =null;// useFlutterwave(config);
  
  const closeTransferModal = () => {
    setOpenTransferModal(false);
  };
  const closeFundingModal = () => {
    setOpenFundingModal(false);
  };
  const closeWithdrawModal = () => {
    setOpenWithdrawModal(false);
  };
  const performTransfer = () => {
    if (transferInput.recipient === "" || transferInput.recipient.length < 3)
      toast.error("Enter valid recipient address");
    else if (transferInput.amount === "" || transferInput.amount <= 0)
      toast.error("Enter valid amount");
    else if (transferInput.narration === "") toast.error("Enter Narration");

    if (
      transferInput.recipient !== "" &&
      transferInput.recipient.length >= 3 &&
      transferInput.amount !== "" &&
      transferInput.amount > 0 &&
      transferInput.narration !== ""
    ) {
      const request = {
        recipient: transferInput.recipient,
        narration: transferInput.narration,
        amount: Number(transferInput.amount),
      };

      setTransferProgress(true);
      unitService
        .fundsTransfer(request)
        .then((result) => {
          setTransferProgress(false);
          if (result.data.success) {
            getUnitDetails();
            toast.success("Transfer successful");
            setOpenTransferModal(false);
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error(e.response.data.message);
          setTransferProgress(false);
        });
    }
  };

  const performWithdrawal = () => {
    if (
      withdrawInput.accountType === "" ||
      withdrawInput.accountType === "Select Bank..."
    )
      toast.error("Select Bank  Name");
    else if (
      withdrawInput.accountName === "" ||
      withdrawInput.accountName.length < 3
    )
      toast.error("Provide Valid  Account Name");
    else if (
      withdrawInput.accountNumber === "" ||
      withdrawInput.accountNumber.length !== 10
    )
      toast.error("Provide valid Account Number");
    else if (withdrawInput.amount === "" || withdrawInput.amount.length < 0)
      toast.error("Provide amount to withdraw");

    if (
      withdrawInput.accountType !== "" &&
      withdrawInput.accountType !== "Select Bank..." &&
      withdrawInput.accountNumber !== "" &&
      withdrawInput.accountNumber.length === 10 &&
      withdrawInput.accountName !== "" &&
      withdrawInput.accountName.length >= 3 &&
      withdrawInput.amount !== "" &&
      withdrawInput.amount.length > 0
    ) {
      const request = {
        accountType: withdrawInput.accountType,
        accountName: withdrawInput.accountName,
        accountNumber: withdrawInput.accountNumber,
        amount: Number(withdrawInput.amount),
      };

      setWithdrawProgress(true);
      unitService
        .withdrawFunds(request)
        .then((result) => {
          setWithdrawProgress(false);
          if (result.data.success) {
            getUnitDetails();
            toast.success("Request successful");
            setOpenWithdrawModal(false);
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error(e.response.data.message);
          setWithdrawProgress(false);
        });
    }
  };
  const performFunding = () => {
    if (fundingInput.amount === "" || fundingInput.amount <= 0)
      toast.error("Enter amount");

    if (fundingInput.amount !== "" && fundingInput.amount > 0) {
      setPaymentRef(util.getRandomString());
      const request = {
        paymentRef: paymentRef,
        channel: "Funding",
        amount: Number(fundingInput.amount),
      };
      setFundingProgress(true);
      handleFlutterPayment({
        callback: (response) => {
          setFundingProgress(false);
          if (response.status && response.status === "completed") {
            request.paymentRef = response.tx_ref;
            request.transactionId = response.transaction_id;
            setFundingProgress(false);
            toast.success("Transaction successful");
            setOpenFundingModal(false);
            getUnitDetails();
            getUnitLogs();
          } else {
            toast.error(response.status);
          }
          closePaymentModal();
        },
        onClose: () => {
          setFundingProgress(false);
          setOpenFundingModal(false);
          getUnitDetails();
        },
      });
    }
  };

  const getInventoryAnalytics = () => {
    inventoryService
      .getInventoryAnalytics()
      .then((response) => {
        if (response.data.success) {
          const data = response.data.data;

          setInventoryAnalytic(data);
          const options = inventoryChartOptions.xaxis.categories;
          const series = [
            {
              name: "Inventory Analytics",
              data: [
                data.total,
                data.totalSold,
                data.totalLeft,
                data.totalDiscount,
                data.totalRevenue,
              ],
            },
          ];

          if (
            user.role === UserRole.User &&
            user.accountType === UserType.Business
          ) {
            const newOptions = options.slice(0, -2);
            const newSeries = series[0].data.slice(0, -2);

            series[0].data = newSeries;
            inventoryChartOptions.xaxis.categories = newOptions;

            setInventoryChartSeries(series);
            setInventoryChartOptions(inventoryChartOptions);
          } else {
            setInventoryChartSeries(series);
          }
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const getUnitAnalytics = () => {
    setUnitProgress(true);
    unitService
      .getUnitAnalytics()
      .then((response) => {
        setUnitProgress(false);
        if (response.data.success) {
          const data = response.data.data;
          const newSeries = [
            {
              name: "  Unit Analytics",
              data: [
                data.totalCredit - data.totalDebit,
                data.totalCredit,
                data.totalDebit,
              ],
            },
          ];
          setUnitAnalytics(data);
          setUnitChartSeries(newSeries);

          console.log("series", newSeries);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => setUnitProgress(false));
  };
  const getUnitLogs = () => {
    setUnitProgress(true);
    unitService
      .getUnitLogs()
      .then((response) => {
        if (response.data.success) {
          setUnitLogs(response.data.data);
          setUnitProgress(false);
          getUnitAnalytics();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        setUnitProgress(false);
      });
  };
  const getUnitDetails = () => {
    setUnitProgress(true);
    unitService
      .getUnit()
      .then((response) => {
        setUnitProgress(false);
        if (response.data.success) {
          setUnit(response.data.data);
          getUnitLogs();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        setUnitProgress(false);
      });
  };

  const performSearch = () => {
    if (searchText && searchText.length >= 2) {
      setSearchProgress(true);
      userService
        .searchBusinesses(searchText)
        .then((result) => {
          setSearchProgress(false);
          if (result.data.success) {
            setSearchResult(result.data.data.page);
          } else {
            setSearchResult(null);
          }
        })
        .catch((e) => {
          setSearchProgress(false);
          setSearchResult(null);
        });
    } else {
      setSearchResult(null);
    }
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
    performSearch();
  };
  useEffect(() => {
    getUnitDetails();
    getInventoryAnalytics();
  }, []);

  return (
    <>
      <div className="panel-header bg-primary-gradient">
        <Dialog open={openTransferModal}>
          <DialogTitle>Funds Transfer</DialogTitle>
          <DialogContent>
            <InputField
              type="text"
              name="recipient"
              value={transferInput.recipient}
              maxLength="6"
              placeholder="Recipient Address"
              label="Recipient  unit address"
              onChange={(e) =>
                setTransferInput({
                  ...transferInput,
                  recipient: e.target.value,
                })
              }
              required
            />
            <InputField
              type="number"
              name="amount"
              value={transferInput.amount}
              placeholder="Amount you want to transfer"
              label="Enter Amount"
              onChange={(e) =>
                setTransferInput({
                  ...transferInput,
                  amount: e.target.value,
                })
              }
              required
            />
            <TextArea
              name="narration"
              label="Narration"
              value={transferInput.narration}
              placeholder="Remark"
              onChange={(e) =>
                setTransferInput({
                  ...transferInput,
                  narration: e.target.value,
                })
              }
              required
            />
          </DialogContent>
          <DialogActions>
            <button
              onClick={closeTransferModal}
              className="btn btn-primary btn-border btn-round mr-2"
            >
              Cancel
            </button>
            <button
              onClick={!transferProgress ? performTransfer : null}
              className="btn btn-primary btn-round"
            >
              {transferProgress ? <TinyLoader /> : "Continue"}
            </button>
          </DialogActions>
        </Dialog>

        <Dialog open={openWithdrawModal}>
          <DialogTitle>Withdraw funds</DialogTitle>
          <DialogContent>
            <OptionField
              children={["Select Bank..."].concat(util.getBanks())}
              name="accountType"
              value={withdrawInput.accountType}
              placeholder="Select Bank"
              label="Select Bank"
              onChange={(e) =>
                setWithdrawInput({
                  ...withdrawInput,
                  accountType: e.target.value,
                })
              }
              required
            />
            <InputField
              type="text"
              name="accountName"
              value={withdrawInput.accountName}
              placeholder="Account name"
              label="Account Name"
              onChange={(e) =>
                setWithdrawInput({
                  ...withdrawInput,
                  accountName: e.target.value,
                })
              }
              required
            />

            <InputField
              type="text"
              name="accountNumber"
              value={withdrawInput.accountNumber}
              placeholder="Account number"
              maxLength="10"
              label="Account Number"
              onChange={(e) =>
                setWithdrawInput({
                  ...withdrawInput,
                  accountNumber: e.target.value,
                })
              }
              required
            />
            <InputField
              type="number"
              name="amount"
              value={withdrawInput.amount}
              placeholder="Amount you want to withdraw"
              label="Enter Amount"
              onChange={(e) =>
                setWithdrawInput({
                  ...withdrawInput,
                  amount: e.target.value,
                })
              }
              required
            />
          </DialogContent>
          <DialogActions>
            <button
              onClick={closeWithdrawModal}
              className="btn btn-primary btn-border btn-round mr-2"
            >
              Cancel
            </button>
            <button
              onClick={!withdrawProgress ? performWithdrawal : null}
              className="btn btn-primary btn-round"
            >
              {withdrawProgress ? <TinyLoader /> : "Continue"}
            </button>
          </DialogActions>
        </Dialog>
        <Dialog open={openFundingModal}>
          <DialogTitle>Buy Unit</DialogTitle>
          <DialogContent>
            <InputField
              type="number"
              value={fundingInput.amount}
              label="Enter Amount"
              placeholder="Amount"
              onChange={(e) =>
                setFundingInput({
                  ...fundingInput,
                  amount: e.target.value,
                })
              }
              required
            />
          </DialogContent>
          <DialogActions>
            <button
              onClick={closeFundingModal}
              className="btn btn-primary btn-border btn-round mr-2"
            >
              Cancel
            </button>
            <button
              onClick={
                !fundingProgress
                  ? () => {
                      performFunding();
                    }
                  : null
              }
              className="btn btn-primary btn-round"
            >
              {fundingProgress ? <TinyLoader /> : "Continue"}
            </button>
          </DialogActions>
        </Dialog>
        <ToastContainer />
        <div className="page-inner py-5">
          <div className="align-items-left align-items-md-center flex-column flex-md-row">
            <div>
              <div className="public-search mb-4">
                <SearchField
                  onChange={onSearch}
                  placeholder="Search businesses or shops around you..."
                />
                <div
                  className="nav-search-result"
                  style={{
                    display:
                      !searchResult && !searchProgress ? "none" : "block",
                  }}
                >
                  {searchProgress ? (
                    <div className="loader-wrapper text-align-center">
                      <Loader />
                    </div>
                  ) : (
                    <ul className="result-set">
                      {searchResult && searchResult.length > 0 ? (
                        searchResult.map((business, index) => {
                          return (
                            <li key={index}>
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={
                                  business.alias
                                    ? "https://fayib.com/" + business.alias
                                    : "#"
                                }
                              >
                                {business.name}
                                <p className="sub-search-text">
                                  {business.businessType} at {business.address}{" "}
                                  {business.state}
                                </p>
                              </a>
                            </li>
                          );
                        })
                      ) : (
                        <>Search not found</>
                      )}
                    </ul>
                  )}
                </div>
              </div>
              <h2 className="text-white pb-2 fw-bold">
                Welcome to <img src={logo} className="body-logo" />
              </h2>
              <h5 className="text-white op-7 mb-2">
                Manage and monitor all your businesses in one place
              </h5>
            </div>

            <div className="d-flex py-2 overflow-x body-action-buttons">
              {user.businessTarget === BusinessTarget.Service ? (
                <>
                  <Link to="/inventory/services">
                    <button className="btn btn-white btn-secondary btn-round long-button">
                      <i className="fa fa-list"></i> Manage Services
                    </button>
                  </Link>
                  &nbsp; &nbsp;
                  <Link to="/inventory/transaction">
                    <button className="btn btn-white btn-white btn-round long-button">
                      <i className="fa fa-plus"></i> Create Transaction
                    </button>
                  </Link>
                  &nbsp; &nbsp;
                  <Link to="/inventory/transactions">
                    <button className="btn btn-secondary btn-danger btn-round long-button">
                      <i className="fa fa-eye"></i> View Transactions
                    </button>
                  </Link>
                </>
              ) : user.businessTarget === BusinessTarget.Product ? (
                <>
                  <Link to="/inventory/products">
                    <button className="btn btn-white btn-secondary btn-round long-button">
                      <i className="fa fa-list"></i> Manage Products
                    </button>
                  </Link>
                  &nbsp; &nbsp;
                  <Link to="/inventory/sale">
                    <button className="btn btn-white btn-white btn-round long-button">
                      <i className="fa fa-plus"></i> Create Order
                    </button>
                  </Link>
                  &nbsp; &nbsp;
                  <Link to="/inventory/sales">
                    <button className="btn btn-secondary btn-danger btn-round long-button">
                      <i className="fa fa-eye"></i> View Sales
                    </button>
                  </Link>
                </>
              ) : (
                <>
                  <Link to="/inventory/products">
                    <button className="btn btn-success btn-danger btn-round long-button">
                      <i className="fa fa-list"></i> Manage Products
                    </button>
                  </Link>
                  &nbsp; &nbsp;
                  <Link to="/inventory/sale">
                    <button className="btn btn-secondary btn-white btn-round long-button">
                      <i className="fa fa-shopping-cart"></i> Create Order
                    </button>
                  </Link>
                  &nbsp; &nbsp;
                  <Link to="/inventory/transaction">
                    <button className="btn btn-white btn-white btn-round long-button">
                      <i className="fa fa-plus"></i> Create Transaction
                    </button>
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="page-inner mt--5">
        <div className="row mt--2">
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                {user.accountType !== UserType.Individual ? (
                  <>
                    <>
                      <div className="d-flex card-title mb-2">
                        <span>
                          {cycle ? titleCase(cycle.toLowerCase()) : "Daily"}{" "}
                          Statistics &nbsp;
                        </span>
                        <Link
                          className="btn link-btn text-primary"
                          to="/inventory/analytics"
                        >
                          <i className="fa fa-eye"></i>View Report
                        </Link>
                      </div>

                      {inventoryAnalytic ? (
                        <div className="row mb-2">
                          <div className="col break-item">
                            <div>
                              <h6 className="fw-bold text-uppercase text-success op-8">
                                {user.businessTarget === BusinessTarget.Service
                                  ? "Total Trans"
                                  : "Total Sales"}
                              </h6>
                              <h4 className="fw-bold">
                                ₦{" "}
                                {inventoryAnalytic.total
                                  ? millify(inventoryAnalytic.total)
                                  : "0.00"}
                              </h4>
                            </div>
                          </div>

                          <div className="col break-item">
                            <div>
                              <h6 className="fw-bold text-uppercase text-success op-8">
                                Total Revenue
                              </h6>
                              <h4 className="fw-bold">
                                ₦{" "}
                                {user.role !== UserRole.User
                                  ? inventoryAnalytic.totalRevenue
                                    ? millify(inventoryAnalytic.totalRevenue)
                                    : "0.00"
                                  : "****"}
                              </h4>
                            </div>
                          </div>
                          <div className="col break-item">
                            <div>
                              <h6 className="fw-bold text-uppercase text-danger op-8">
                                Total Discount
                              </h6>
                              <h4 className="fw-bold">
                                ₦{" "}
                                {inventoryAnalytic.totalDiscount
                                  ? millify(inventoryAnalytic.totalDiscount)
                                  : "0.00"}
                              </h4>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>

                    <div className="card-category">
                      See what you sold, earn and whats left.
                    </div>
                    <Chart
                      options={inventoryChartOptions}
                      series={inventoryChartSeries}
                      type="area"
                      width={100 + "%"}
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card">
              {unitProgress ? (
                <Loader></Loader>
              ) : (
                <div className="card-body">
                  <div className="card-title">
                    Unit Overview{" "}
                    <div className=" unit-id user-code">
                      <i
                        className="copy-btn fa fa-copy"
                        onClick={() => {
                          navigator.clipboard.writeText(unit.code);
                          toast.success("Copied");
                        }}
                      ></i>{" "}
                      {unit ? unit.code : ""}
                    </div>
                  </div>

                  <div className="row py-3">
                    <div className="col break-item">
                      <div>
                        <h6 className="fw-bold text-uppercase text-success op-8">
                          Balance
                        </h6>
                        <h4 className="fw-bold">
                          <del className="fayib-unit">F</del>{" "}
                          {unit ? millify(unit.balance) : "0.00"}
                        </h4>
                      </div>
                    </div>
                    <div className="col break-item">
                      <div>
                        <h6 className="fw-bold text-uppercase text-danger op-8">
                          Total Debit
                        </h6>
                        <h4 className="fw-bold">
                          <del className="fayib-unit">F</del>{" "}
                          {unitAnalytics
                            ? millify(unitAnalytics.totalDebit)
                            : "0.00"}
                        </h4>
                      </div>
                    </div>
                    <div className="col break-item">
                      <div>
                        <h6 className="fw-bold text-uppercase text-success op-8">
                          Total Credit
                        </h6>
                        <h4 className="fw-bold">
                          <del className="fayib-unit">F</del>{" "}
                          {unitAnalytics
                            ? millify(unitAnalytics.totalCredit)
                            : "0.00"}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="ml-md-auto py-2 py-md-0">
                    <button
                      className="btn btn-secondary btn-round mr-2 mb-2"
                      onClick={() => {
                        setOpenFundingModal(true);
                      }}
                    >
                      <i className="fa fa-plus"></i> Buy Unit
                    </button>

                    <button
                      onClick={() => {
                        setOpenTransferModal(true);
                      }}
                      className="btn btn-primary btn-round mr-2 mb-2"
                    >
                      <i className="fa fa-arrow-right"></i> Transfer Units
                    </button>

                    {/* <button
                      onClick={() => {
                        setOpenWithdrawModal(true);
                      }}
                      className="btn btn-danger btn-round mr-2 mb-2"
                    >
                      <i className="fa fa-tag"></i> Withdraw Units
                    </button> */}
                  </div>
                  <Chart
                    options={unitChartOptions}
                    series={unitChartSeries}
                    type="area"
                    width={100 + "%"}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="card">
              <div className="card-header">How it works?</div>
              <div className="card-body">
                <ol className="activity-feed">
                  <li className="feed-item feed-item-secondary">
                    <time className="date" dateTime="9-25">
                      1
                    </time>
                    <span className="text">Registr products or services</span>
                  </li>
                  <li className="feed-item feed-item-success">
                    <time className="date" dateTime="9-24">
                      2
                    </time>
                    <span className="text">Make a sale or transaction</span>
                  </li>
                  <li className="feed-item">
                    <time className="date" dateTime="9-17">
                      3
                    </time>
                    <span className="text">
                      Revenue and loses is auto generated
                    </span>
                  </li>
                  <li className="feed-item feed-item-info">
                    <time className="date" dateTime="9-23">
                      5
                    </time>
                    <span className="text">View and download daily report</span>
                  </li>
                  <li className="feed-item feed-item-warning">
                    <time className="date" dateTime="9-21">
                      6
                    </time>
                    <span className="text">
                      Manage your sells and{" "}
                      <Link to="/inventory">"products"</Link> on inventory
                    </span>
                  </li>
                  <li className="feed-item feed-item-danger">
                    <time className="date" dateTime="9-18">
                      9
                    </time>
                    <span className="text">
                      Track profits and loses of your{" "}
                      <Link to="/analytics">"Business"</Link> easily
                    </span>
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card">
              <div className="card-header">Top Transaction History</div>
              <div className="card-body">
                {unitProgress ? (
                  <Loader />
                ) : unitLogs != null ? (
                  <div>
                    <div className="card-body p-0">
                      <div className="table-responsive table-hover table-sales">
                        <table className="table table-striped">
                          <tbody>
                            {unitLogs
                              .filter((item, index) => index < 8)
                              .map((data, index) => {
                                return (
                                  <tr key={index}>
                                    <th>{data.activity}</th>
                                    <td>{data.channel}</td>
                                    <td> {format(data.createdAt)}</td>
                                    <td>
                                      {" "}
                                      <h6
                                        className={
                                          data.activity === "DEBIT"
                                            ? "text-danger fw-bold"
                                            : "text-info fw-bold"
                                        }
                                      >
                                        {data.activity === "CREDIT" ? "+" : "-"}{" "}
                                        <del className="fayib-unit">F</del>
                                        {millify(data.amount)}
                                      </h6>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="card-body p-0">
                    <div className="table-responsive table-hover table-sales">
                      <p>No Transaction found</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashBody;
